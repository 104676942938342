import { toQueryString } from "./toQueryString";

interface SignInParams {
  params?: Record<string, any>;
}

interface AuthenticateWithProviderParams {
  provider: string;
  communityId: string;
}

export const userPaths = {
  signIn: ({ params = {} }: SignInParams = {}) =>
    `/users/sign_in?${toQueryString(params)}`,
  authenticateWithProvider: ({
    provider,
    communityId,
  }: AuthenticateWithProviderParams) =>
    `/users/auth/${provider}?community_id=${communityId}`,
};
