import type {
  CircleLocale,
  LocaleBcp47,
} from "@circle-react/types/CircleLocale";

export const circleLocaleToBcp47: Record<CircleLocale, LocaleBcp47> = {
  en: "en-US",
  pt: "pt-BR",
};

export const getLocale = (locale?: CircleLocale): LocaleBcp47 => {
  if (!locale) {
    return "en-US";
  }

  return circleLocaleToBcp47[locale] || "en-US";
};

export const getCurrentCircleLocale = (): CircleLocale =>
  window?.locale ?? "en";

export const getCurrentBcp47Locale = (): LocaleBcp47 =>
  getLocale(window?.locale);

export const dynamicVariablesCorrectedTranslation = (translation: string) =>
  translation.replace(/{([\w.]+)}/g, "{{$1}}");
