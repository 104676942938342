import { toQueryString } from "../toQueryString";
import { absolutePath } from "./absolutePath";

export const settings = {
  home: {
    index: ({ params = {} } = {}) => absolutePath("../settings/home", params),
    update: ({ params = {} } = {}) => absolutePath("../settings/home", params),
  },
  landingPage: {
    welcomeBanner: {
      index: ({ params = {} } = {}) =>
        absolutePath(
          "../settings/landing_page/welcome_banner_preference",
          params,
        ),
    },
  },
  attachedFiles: {
    destroy: () => absolutePath("../settings/communities/attached_files"),
  },
  openGraphImage: {
    destroy: ({ uuid, params = {} } = {}) =>
      absolutePath(`../opengraph_images/${uuid}`, params),
  },
  courses: {
    lessons: {
      index: ({ params = {} } = {}) =>
        absolutePath("settings/courses/lessons", params),
      show: ({ id, params = {} } = {}) =>
        absolutePath(`settings/courses/lessons/${id}`, params),
    },
    quizzes: {
      index: ({ params = {} } = {}) =>
        absolutePath("settings/courses/quizzes", params),
    },
    sections: {
      index: ({ params = {} } = {}) =>
        absolutePath("settings/courses/sections", params),
      show: ({ id, params = {} } = {}) =>
        absolutePath(`settings/courses/sections/${id}`, params),
    },
  },
  posts: {
    index: ({ params = {} } = {}) => absolutePath("settings/posts", params),
  },
  analytics: {
    analyticsIframe: ({ params = {}, options = {} }) =>
      absolutePath("settings/analytics_iframe", params, options),
    analyticsExport: ({ params = {} } = {}) =>
      absolutePath("settings/analytics_exports", params),
  },
  mobileApp: {
    show: () => absolutePath("settings/mobile_app"),
    update: () => absolutePath("settings/mobile_app"),
  },
  mobileHomeSegments: {
    update: id => absolutePath(`settings/mobile_home_segments/${id}`),
  },
  memberTags: {
    index: ({ params = {} } = {}) =>
      absolutePath("settings/member_tags", params),
    show: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/member_tags/${uuid}`, params),
    create: ({ params = {} } = {}) =>
      absolutePath("settings/member_tags", params),
    update: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/member_tags/${uuid}`, params),
    destroy: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/member_tags/${uuid}`, params),
  },
  postBulkActions: {
    index: ({ params = {} } = {}) => absolutePath("posts/bulk_actions", params),
    show: ({ id, params = {} } = {}) =>
      absolutePath(`posts/bulk_actions/${id}`, params),
    create: ({ params = {} } = {}) =>
      absolutePath("posts/bulk_actions", params),
  },
  topics: {
    index: ({ params = {} } = {}) => absolutePath("settings/topics", params),
    show: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/topics/${uuid}`, params),
    create: ({ params = {} } = {}) => absolutePath("settings/topics", params),
    update: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/topics/${uuid}`, params),
    destroy: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/topics/${uuid}`, params),
  },
  api: {
    index: ({ params = {} } = {}) => absolutePath("settings/api", params),
    revoke: ({ uuid, params = {} } = {}) =>
      absolutePath(`settings/api/${uuid}/revoke`, params),
  },
  activityLogs: {
    index: ({ params = {} } = {}) =>
      absolutePath("settings/activity_logs", params),
    exportCsv: params =>
      `/settings/members/activity_logs/export.csv?${toQueryString(params)}`,
    entityPath: ({ id } = {}) =>
      `/settings/members/activity_logs/${id}/entity_path`,
    sourcePath: ({ id } = {}) =>
      `/settings/members/activity_logs/${id}/source_path`,
  },
  v3_ui_preference: {
    update: () => "/settings/v3",
  },
  legal: {
    show: () => absolutePath(`settings/legal`),
    update: () => absolutePath(`settings/legal`),
  },
  navigation: {
    list: () => absolutePath(`settings/header_menu_links`),
    reorder: () => absolutePath(`settings/header_menu_links/reorder`),
    create: () => absolutePath(`settings/header_menu_links`),
    edit: ({ id } = {}) => absolutePath(`settings/header_menu_links/${id}`),
    show: ({ id } = {}) => absolutePath(`settings/header_menu_links/${id}`),
    destroy: ({ id } = {}) => absolutePath(`settings/header_menu_links/${id}`),
  },
};
