import type { ReactNode } from "react";
import { createContext, useContext } from "react";
import { useQuery } from "react-query";
import { internalApi } from "@/react/helpers/urlHelpers";

export interface LayoutPartialsContextProps {
  data?: any;
}

const LayoutPartialsContext = createContext<LayoutPartialsContextProps>({});
LayoutPartialsContext.displayName = "LayoutPartialsContext";

export const LayoutPartialsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const value = useQuery(internalApi.layoutPartials.show());

  return (
    <LayoutPartialsContext.Provider value={{ ...value }}>
      {children}
    </LayoutPartialsContext.Provider>
  );
};

export const useLayoutPartialsContext = () => useContext(LayoutPartialsContext);
