interface FetchParams {
  page: string;
  perPage: string;
  notificationGroup: string;
}

interface IdParams {
  id: string;
}

interface UnfollowParams {
  postId: string;
  followerId: string;
}

interface FollowParams {
  postId: string;
}

interface FetchNotificationCountParams {
  communityId: string;
}

export const notificationsApi = {
  fetch: (params: FetchParams) =>
    `/notifications?page=${params.page}&per_page=${params.perPage}&notification_group=${params.notificationGroup}`,
  markAsRead: (params: IdParams) => `/notifications/${params.id}/mark_as_read`,
  markAllAsRead: () => "/notifications/mark_all_as_read",
  archive: (params: IdParams) => `/notifications/${params.id}/archive`,
  unfollow: (params: UnfollowParams) =>
    `/posts/${params.postId}/post_followers/${params.followerId}`,
  follow: (params: FollowParams) => `/posts/${params.postId}/post_followers`,
  resetNotificationCount: () => `/notifications/reset_new_notifications_count`,
  fetchNotificationCount: (params: FetchNotificationCountParams) =>
    `/notifications/new_notifications_count?community_id=${params.communityId}`,
};
