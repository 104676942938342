import { absolutePath } from "./absolutePath";

export const apiAnalytics = {
  get: (startDateTime, endDateTime) =>
    absolutePath("/admin/api_analytics/usage_counts", {
      start_date_time: startDateTime,
      end_date_time: endDateTime,
    }),
  getEndpointMetrics: (startDateTime, endDateTime, apiType) =>
    absolutePath("/admin/api_analytics/endpoint_metrics", {
      start_date_time: startDateTime,
      end_date_time: endDateTime,
      api_type: apiType,
    }),
};
