import { toQueryString } from "../toQueryString";

const path = (path: any, params: any = {}) =>
  `${path}?${toQueryString(params)}`;

export const contactMarketingEmailPreferences = {
  update: ({
    contactSqid,
    params = {},
  }: {
    contactSqid: string;
    params?: object;
  }) =>
    path(
      `/internal_api/contacts/${contactSqid}/marketing_email_preference`,
      params,
    ),
};
