import { absolutePath } from "./absolutePath";

const lessonPath = ({ courseId, sectionId, lessonId, params = {} } = {}) =>
  absolutePath(
    `courses/${courseId}/sections/${sectionId}/lessons/${lessonId}`,
    params,
  );

export const courses = {
  adminSummary: ({ courseId, params = {} }) =>
    absolutePath(`courses/${courseId}/stats`, params),
  students: {
    index: ({ courseId, params = {} }) =>
      absolutePath(`courses/${courseId}/students`, params),
    show: ({ courseId, spaceMemberId, params = {} }) =>
      absolutePath(`courses/${courseId}/students/${spaceMemberId}`, params),
  },
  quizAttempts: {
    index: ({ courseId, params = {} }) =>
      absolutePath(`courses/${courseId}/quiz_attempts`, params),
  },
  listSections: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/sections`, params),
  reorder: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/reorders`, params),
  createLesson: ({ courseId, sectionId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}/lessons`, params),
  getLesson: lessonPath,
  createSection: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/sections`, params),
  updateLesson: lessonPath,
  deleteLesson: lessonPath,
  updateSection: ({ courseId, sectionId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}`, params),
  deleteSection: ({ courseId, sectionId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/sections/${sectionId}`, params),
  settings: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/settings`, params),
  listComments: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/lesson_comments`, params),
  lessonProgress: {
    update: ({ courseId, lessonId, params = {} } = {}) =>
      absolutePath(`courses/${courseId}/lessons/${lessonId}/progress`, params),
  },
  lessonFiles: {
    index: ({ courseId, lessonId, params = {} } = {}) =>
      absolutePath(`courses/${courseId}/lessons/${lessonId}/files`, params),
    destroy: ({ courseId, lessonId, fileId, params = {} } = {}) =>
      absolutePath(
        `courses/${courseId}/lessons/${lessonId}/files/${fileId}`,
        params,
      ),
  },
  exports: ({ courseId, params = {} } = {}) =>
    absolutePath(`courses/${courseId}/exports`, params),
};
