import { toQueryString } from "../toQueryString";
import { absolutePath } from "./absolutePath";

export const experiments = {
  getVariant: ({
    experiment,
    control,
    alternatives,
    is_legible_for_experiment,
  } = {}) =>
    absolutePath(
      `experiments/variant?${toQueryString({
        experiment,
        control,
        alternatives,
        is_legible_for_experiment,
      })}`,
    ),
  finish: () => absolutePath("experiments/finished"),
  userid: () => absolutePath("experiments/userid"),
  uniqueIdentifier: () => absolutePath("experiments/unique_identifier"),
  isAlreadyPartOfAnotherCommunity: () =>
    absolutePath("experiments/is_already_part_of_another_community"),
};
