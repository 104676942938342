import { gamification } from "@circle-react/helpers/urlHelpers/internalApi/gamification";
import { accountAuthentication } from "./accountAuthentication";
import { accountPaymentProcessors } from "./accountPaymentProcessors";
import { AccountSubscriptions } from "./accountSubscriptions";
import { actionTextDirectUpload } from "./actionTextDirectUpload";
import { admin } from "./admin";
import { apiAnalytics } from "./apiAnalytics";
import { apiTokens } from "./apiTokens";
import { asyncTasks } from "./asyncTasks";
import { authProviders } from "./authProviders";
import { bookmarks } from "./bookmarks";
import { broadcasts } from "./broadcasts";
import { chatPreferences } from "./chatPreferences";
import { chatRoomMessages } from "./chatRoomMessages";
import { chatRoomParticipants } from "./chatRoomParticipants";
import { chatRooms } from "./chatRooms";
import { chatThreads } from "./chatThreads";
import { comments } from "./comments";
import { communities } from "./communities";
import { communityBot } from "./communityBot";
import { communityInbox } from "./communityInbox";
import { communityLead } from "./communityLead";
import { communityMember } from "./communityMember";
import { communityMemberBulkInvitations } from "./communityMemberBulkInvitations";
import { communityMemberCharge } from "./communityMemberCharge";
import { communityMemberPaymentMethod } from "./communityMemberPaymentMethod";
import { communityMemberPaymentProcessor } from "./communityMemberPaymentProcessor";
import { communityMemberSubscription } from "./communityMemberSubscription";
import { communityMemberSubscriptionPriceChanges } from "./communityMemberSubscriptionPriceChanges";
import { communityMembers } from "./communityMembers";
import { communityPlanAddons } from "./communityPlanAddons";
import { contactMarketingEmailPreferences } from "./contactMarketingEmailPreferences";
import { corsAllowedOrigins } from "./corsAllowedOrigins";
import { courseTopics } from "./courseTopics";
import { courses } from "./courses";
import { customDomain } from "./customDomain";
import { embeds } from "./embeds";
import { entityPreviews } from "./entityPreviews";
import { eventAttendees } from "./eventAttendees";
import { eventLiveStreams } from "./eventLiveStreams";
import { events } from "./events";
import { experiments } from "./experiments";
import { flaggedContent } from "./flaggedContent";
import { forms } from "./forms";
import { homePagePosts } from "./homePagePosts";
import { imagePosts } from "./imagePosts";
import { importContacts } from "./importContacts";
import { invitationLinks } from "./invitationLinks";
import { landingPageSettings } from "./landingPageSettings";
import { layoutPartials } from "./layoutPartials";
import { liveStreams } from "./liveStreams";
import { manageMembers } from "./manageMembers";
import { marketingEmailAddressSettings } from "./marketingEmailAddressSettings";
import { marketingEmailPreferences } from "./marketingEmailPreferences";
import { marketingHubStatus } from "./marketingHubStatus";
import { masquerading } from "./masquerading";
import { mediaSettings } from "./mediaSettings";
import { mediaTranscodings } from "./mediaTranscodings";
import { mediaTranscripts } from "./mediaTranscripts";
import { memberActivityScore } from "./memberActivityScore";
import { memberTags } from "./memberTags";
import { moderationSettings } from "./moderationSettings";
import { notificationPreferences } from "./notificationPreferences";
import { notifications } from "./notifications";
import { paywallCheckout } from "./paywallCheckout";
import { paywallCoupons } from "./paywallCoupons";
import { paywallGroup } from "./paywallGroup";
import { paywalls } from "./paywalls";
import { paywallsAffiliatesSettings } from "./paywallsAffiliatesSettings";
import { pinnedChatRoom } from "./pinnedChatRoom";
import { planFeatureFlags } from "./planFeatureFlags";
import { plans } from "./plans";
import { polls } from "./polls";
import { postDetails } from "./postDetails";
import { postFollowers } from "./postFollowers";
import { posts } from "./posts";
import { profile } from "./profile";
import { profileFields } from "./profileFields";
import { punditUser } from "./punditUser";
import { quizAttempts } from "./quizAttempts";
import { reactions } from "./reactions";
import { recurringEvents } from "./recurringEvents";
import { richTextFields } from "./richTextFields";
import { scheduledEmails } from "./scheduledEmails";
import { search, searchV2 } from "./search";
import { segments } from "./segments";
import { settings } from "./settings";
import { sidebarNotifications } from "./sidebarNotifications";
import { spaceGroups } from "./spaceGroups";
import { spaceMembers } from "./spaceMembers";
import { spaces } from "./spaces";
import { submissions } from "./submissions";
import { supportWidget } from "./supportWidget";
import { supportedCurrencies } from "./supportedCurrencies";
import { topics } from "./topics";
import { upcomingEvents } from "./upcomingEvents";
import { voiceMessages } from "./voiceMessages";
import { workflowsUpcomingEvents } from "./workflowsUpcomingEvents";

export const internalApi = {
  actionTextDirectUpload,
  admin,
  asyncTasks,
  apiTokens,
  bookmarks,
  chatRoomMessages,
  chatRoomParticipants,
  chatRooms,
  chatPreferences,
  comments,
  communities,
  communityLead,
  contactMarketingEmailPreferences,
  communityMember,
  communityMemberCharge,
  communityMemberPaymentMethod,
  communityMemberPaymentProcessor,
  communityMembers,
  communityMemberSubscription,
  communityMemberSubscriptionPriceChanges,
  communityPlanAddons,
  customDomain,
  accountAuthentication,
  authProviders,
  courses,
  quizAttempts,
  embeds,
  entityPreviews,
  eventAttendees,
  eventLiveStreams,
  events,
  experiments,
  flaggedContent,
  forms,
  gamification,
  homePagePosts,
  imagePosts,
  invitationLinks,
  layoutPartials,
  liveStreams,
  manageMembers,
  marketingHubStatus,
  memberTags,
  moderationSettings,
  notificationPreferences,
  notifications,
  paywallCheckout,
  paywallCoupons,
  paywallGroup,
  paywalls,
  paywallsAffiliatesSettings,
  planFeatureFlags,
  polls,
  postDetails,
  postFollowers,
  posts,
  profile,
  profileFields,
  punditUser,
  reactions,
  recurringEvents,
  richTextFields,
  search,
  searchV2,
  segments,
  settings,
  sidebarNotifications,
  spaceGroups,
  spaceMembers,
  spaces,
  submissions,
  supportedCurrencies,
  supportWidget,
  upcomingEvents,
  voiceMessages,
  workflowsUpcomingEvents,
  chatThreads,
  plans,
  AccountSubscriptions,
  memberActivityScore,
  mediaSettings,
  mediaTranscodings,
  mediaTranscripts,
  accountPaymentProcessors,
  landingPageSettings,
  masquerading,
  topics,
  courseTopics,
  communityMemberBulkInvitations,
  importContacts,
  scheduledEmails,
  broadcasts,
  marketingEmailPreferences,
  marketingEmailAddressSettings,
  pinnedChatRoom,
  corsAllowedOrigins,
  apiAnalytics,
  communityInbox,
  communityBot,
};
