import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const UNDERGROUND_COLORS: PartialTheme = {
  light: {
    "--brand": "#DFB314",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#333136",
    "--color-community-header-background": "#333136",
    "--color-community-sidebar-text": "#C0C0C0",
    "--color-community-header-text": "#C0C0C0",
    "--color-community-sidebar-active-background": "#DFB314",
    "--color-community-active-item-text-color": "#0A0A0A",
    "--color-community-header-hover-background": "#292929",
    "--color-community-sidebar-hover-background": "#292929",
    "--color-online-background": "#FF9270",
    "--color-community-sidebar-badge-background": "#FDAC86",
    "--color-community-header-active-text": "#C0C0C0",
    "--color-community-header-active-background": "#292929",
  },
  dark: {
    "--brand": "#FBDA60",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#0A0A0A",
    "--color-community-header-background": "#0A0A0A",
    "--color-community-sidebar-text": "#C0C0C0",
    "--color-community-header-text": "#C0C0C0",
    "--color-community-sidebar-active-background": "#FBDA60",
    "--color-community-active-item-text-color": "#0A0A0A",
    "--color-community-header-hover-background": "#292929",
    "--color-community-sidebar-hover-background": "#292929",
    "--color-online-background": "#FF9270",
    "--color-community-sidebar-badge-background": "#FDAC86",
    "--color-community-header-active-text": "#C0C0C0",
    "--color-community-header-active-background": "#292929",
  },
};

export const UNDERGROUND_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...UNDERGROUND_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...UNDERGROUND_COLORS.dark,
  },
};
