import type { PaginationParams } from "@circle-react/types/InternalAPIHelper";
import { absolutePath } from "../../absolutePath";
import { attributions } from "./attributions";

export const affiliates = {
  index: (params: PaginationParams) =>
    absolutePath(`paywalls/affiliates`, params),
  invite: () => absolutePath(`paywalls/affiliates/invite`),
  payouts: {
    controls: () => absolutePath(`paywalls/affiliates/payouts/controls`),
    start: () => absolutePath(`paywalls/affiliates/payouts/start`),
    export: (params: { community_member_ids?: number[] }) =>
      absolutePath(`paywalls/affiliates/payouts/export`, params),
    markAsPaid: () => absolutePath(`paywalls/affiliates/payouts/mark_paid`),
  },
  attributions,
};
