import { isEmpty } from "lodash";
import { usePunditUserContext } from "@circle-react/contexts";

export const usePlanHasCustomizeCommunity = () => {
  const { currentCommunity = {} } = usePunditUserContext();
  const { customize_community_feature_flag_enabled } = currentCommunity;
  return Boolean(customize_community_feature_flag_enabled);
};

export const useIsDarkModeBeta = () => {
  const { currentCommunitySettings = {} } = usePunditUserContext();
  const { dark_mode_enabled } = currentCommunitySettings;

  if (isEmpty(currentCommunitySettings)) {
    return true;
  }

  return Boolean(dark_mode_enabled);
};

export const useIsCustomizeCommunityAvailable = () => {
  const hasPlanCustomize = usePlanHasCustomizeCommunity();
  const isDarkmodeBeta = useIsDarkModeBeta();
  const { currentCommunitySettings = {} } = usePunditUserContext();
  const { customize_community_enabled } = currentCommunitySettings;
  const isCustomizeBeta = Boolean(customize_community_enabled);

  return isDarkmodeBeta && isCustomizeBeta && hasPlanCustomize;
};
