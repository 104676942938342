import type { ComponentType, LazyExoticComponent } from "react";
// eslint-disable-next-line no-restricted-imports -- False positive since it's part of the lazyWithRetry implementation
import { lazy } from "react";

// https://www.bitahoy.com/blog/post/fixing-chunk-load-errors-in-webpack-and-react-js
const importWithRetry = <T extends ComponentType<any>>(
  importFunction: () => Promise<{ default: T }>,
) =>
  new Promise<{ default: T }>((resolve, reject) => {
    const functionString = importFunction.toString();
    const functionBase64 = btoa(functionString);
    const storageKey = `retry-lazy-refreshed-${functionBase64}`;
    let hasRefreshed = false;

    try {
      const storageValue = window.sessionStorage.getItem(storageKey) || "false";
      hasRefreshed = storageValue === "true";
    } catch (error) {
      console.warn(
        "Session storage is not accessible, unable to refresh on failed imports.",
      );
      hasRefreshed = true;
    }

    importFunction()
      .then(component => {
        if (component === undefined) {
          throw new Error("Lazy loaded component is undefined");
        }
        try {
          window.sessionStorage.setItem(storageKey, "false");
        } catch (error) {
          // Session storage is not accessible, ignore the error
        }
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          try {
            window.sessionStorage.setItem(storageKey, "true");
          } catch (error) {
            // Session storage is not accessible, ignore the error
          }
          console.warn("Refreshing page...");
          window.location.reload();
        } else {
          console.error("Failed to import component after refresh:", error);
          reject(error);
        }
      });
  });

export const lazyWithRetry = <T extends ComponentType<any>>(
  importFunction: () => Promise<{ default: T }>,
): LazyExoticComponent<T> => lazy(() => importWithRetry(importFunction));
