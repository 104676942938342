import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const POP_ROCKS_COLORS: PartialTheme = {
  light: {
    "--brand": "#E66F5B",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FEF3ED",
    "--color-community-header-text": "#3E3747",
    "--color-community-sidebar-background": "#FEF3ED",
    "--color-community-sidebar-text": "#3E3747",
    "--color-community-sidebar-active-background": "#E66F5B",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#FFEADE",
    "--color-community-sidebar-hover-background": "#FFEADE",
    "--color-online-background": "#17A7BB",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#3E3747",
    "--color-community-header-active-background": "#FFEADE",
  },
  dark: {
    "--brand": "#E66F5B",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FEF3ED",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FEF3ED",
    "--color-community-sidebar-active-background": "#E66F5B",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#796154",
    "--color-community-sidebar-hover-background": "#796154",
    "--color-online-background": "#17A7BB",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#FEF3ED",
    "--color-community-header-active-background": "#796154",
  },
};

export const POP_ROCKS_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...POP_ROCKS_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...POP_ROCKS_COLORS.dark,
  },
};
