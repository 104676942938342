import { absolutePath } from "./absolutePath";

export const memberTags = {
  index: ({
    params = {},
  }: {
    params: {
      ids?: number[];
      query?: string;
      page?: number;
      except_ids?: number[];
      per_page?: number;
    };
  }) => absolutePath("member_tags", params),
  show: ({ id, params = {} }: { id: number; params: object }) =>
    absolutePath(`member_tags/${id}`, params),
};
