import slugify from "slugify";
import { authPathsWithRedirect } from "@/react/helpers/urlHelpers";
import type { Community } from "@circle-react/types/Community";

export const PLATFORMS = Object.freeze({
  Web: "web",
  PLAY_STORE: "play_store",
  APP_STORE: "app_store",
});

export const hasFeaturedAreas = ({
  home_page_enabled,
  weekly_digest_enabled,
}: any) => home_page_enabled || weekly_digest_enabled;

export const areOnBoardingStepsComplete = (community: any) => {
  const {
    prefs: { has_spaces, has_posts, has_invited_member, has_seen_widget },
  } = community;
  return has_spaces && has_posts && has_invited_member && has_seen_widget;
};

export const isPrivateCommunity = (community: any) => community?.is_private;

export const isPublicCommunity = (community: any) =>
  community && !isPrivateCommunity(community);

export const isTrialCommunity = (community: Community) =>
  Boolean(community.is_on_trial);

export const shouldShowSignupLink = (community: any) =>
  community?.show_signup_link;

export const getCommunityLogoUrl = (currentCommunity: any) => {
  if (currentCommunity?.logo_url) {
    return currentCommunity.logo_url;
  }
  if (currentCommunity?.icon_url) {
    return currentCommunity.icon_url;
  }
};

export const getCommunityLogoUrlAndType = (
  currentCommunity: any,
  isDarkAppearance: boolean = false,
) => {
  const darkLogo: string | null = currentCommunity?.dark_logo_url;
  const darkIcon: string | null = currentCommunity?.dark_icon_url;

  const logoURL: string | null =
    isDarkAppearance && darkLogo ? darkLogo : currentCommunity?.logo_url;

  const iconURL: string | null =
    isDarkAppearance && darkIcon ? darkIcon : currentCommunity?.icon_url;

  const type: "logo" | "icon" = logoURL ? "logo" : "icon";
  return { url: logoURL ?? iconURL, type };
};

export const slugFromCommunityName = (name: string) =>
  slugify(name, {
    replacement: "-",
    remove: /[*+~.;()'"!:@]/g,
    lower: true,
  });

export const isPaymentProcessorEnabled = (community: any) =>
  community?.has_payment_processor_enabled;

// be careful when using this as it encapsulates multiple states
// as cancelled (not on grace period, incomplete, incomplete_expired)
export const isSubscriptionExpired = (community: any) =>
  Boolean(community?.subscription_cancelled);

export const isPromoCodeEnabled = (community: any) =>
  !!community?.promotion_enabled;

export const communitySignInUrl = (community: any, params: object) => {
  const urlObj = new URL(community.sign_in_url);

  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });

  return urlObj.toString();
};

export const communitySignupUrl = (community: any) => {
  if (community?.policies?.custom_login_custom_sign_up) {
    return community.custom_sign_up_link_url;
  }
  if (community?.show_signup_link) {
    return authPathsWithRedirect.signUp();
  }
  return null;
};

export const getIsLocalStorageCacheEnabled = () =>
  window.localstorage_cache_enabled === "true";
