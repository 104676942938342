import { createContext, useContext, useEffect, useState } from "react";
import type { ReactNode } from "react";
import { communityApi } from "../api";
import { LOADED, LOADING, REFRESHING } from "./loadingStatuses";

interface CommunityFeatureFlagItem {
  [key: string]: boolean | number | object;
}

interface CommunityFeatureFlagsContextValue {
  status: string;
  record: CommunityFeatureFlagItem;
  actions: object;
  helpers: object;
}

interface CommunityFeatureFlagsContextProviderProps {
  communityBetaFeatures?: CommunityFeatureFlagItem;
  children: ReactNode;
}

const initialState: CommunityFeatureFlagsContextValue = {
  status: LOADING,
  record: {},
  actions: {}, // Action modify the state
  helpers: {}, // helper read the state to parse/extract information
};

const CommunityFeatureFlagsContext = createContext(initialState);
CommunityFeatureFlagsContext.displayName = "CommunityFeatureFlagsContext";

export const CommunityFeatureFlagsContextProvider = ({
  communityBetaFeatures,
  children,
}: CommunityFeatureFlagsContextProviderProps) => {
  const [status, setStatus] = useState(initialState.status);
  const [record, setRecord] = useState(initialState.record);

  const onDataLoadSuccess = (data: any) => {
    setRecord(data);
    setStatus(LOADED);
  };

  useEffect(() => {
    if (communityBetaFeatures) {
      setRecord(communityBetaFeatures);
      setStatus(LOADED);
    } else {
      void loadData();
    }
  }, []);

  const loadData = async () => {
    const response = await communityApi.communitySettings();
    if (response.ok) {
      const data = await response.json();
      onDataLoadSuccess(data.community_settings);
    } else {
      // Add error handling. May be show toast message
    }
  };

  const refresh = async () => {
    setStatus(REFRESHING);
    await loadData();
  };

  const isDataLoaded = () => status === LOADED;
  const isDataLoading = () => status === LOADING;
  const isDataRefreshing = () => status === REFRESHING;

  const value: CommunityFeatureFlagsContextValue = {
    status,
    record,
    actions: { loadData, refresh },
    helpers: {
      isDataLoading,
      isDataRefreshing,
      isDataLoaded,
    },
  };

  return (
    <CommunityFeatureFlagsContext.Provider value={value}>
      {children}
    </CommunityFeatureFlagsContext.Provider>
  );
};

export const useCommunityFeatureFlagsContext = () =>
  useContext(CommunityFeatureFlagsContext);
