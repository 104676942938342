import { createContext, useContext, useEffect, useState } from "react";
import type { ReactNode } from "react";
import type { Community } from "@circle-react/types/Community";
import { communityApi } from "../api";
import type { LoadingStatus } from "./loadingStatuses";
import { LOADED, LOADING, REFRESHING } from "./loadingStatuses";

const initialState = {
  status: LOADING,
  record: {
    home_page_cover_image: undefined,
  },
  actions: {
    refresh: () => Promise.resolve(),
    loadData: () => Promise.resolve(),
  },
  helpers: {
    isDataRefreshing: () => false,
    isDataLoading: () => false,
    isDataLoaded: () => false,
    communityVisibilityText: () => "Public",
  },
};

export interface Actions {
  refresh: () => Promise<void>;
  loadData: () => Promise<void>;
}

export interface Helpers {
  isDataRefreshing: () => boolean;
  isDataLoading: () => boolean;
  isDataLoaded: () => boolean;
  communityVisibilityText: () => string;
}

export interface CurrentCommunityContextValue {
  status: LoadingStatus;
  record: Partial<Community>;
  actions: Actions;
  helpers: Helpers;
}

const CurrentCommunityContext =
  createContext<CurrentCommunityContextValue>(initialState);
CurrentCommunityContext.displayName = "CurrentCommunityContext";

export interface CurrentCommunityContextProps {
  children: ReactNode;
  community?: Community;
  communityId?: number;
}

export const CurrentCommunityContextProvider = ({
  children,
  community,
  communityId,
}: CurrentCommunityContextProps) => {
  const [status, setStatus] = useState<LoadingStatus>(LOADING);
  const [record, setRecord] = useState<Partial<Community>>(initialState.record);
  const { is_private: isPrivate } = record;

  const onDataLoadSuccess = (data: any) => {
    setRecord(data);
    setStatus(LOADED);
  };

  useEffect(() => {
    if (community) {
      setRecord(community);
      setStatus(LOADED);
    } else {
      void loadData();
    }
  }, []);

  const loadData = async () => {
    if (!communityId) {
      console.warn(`findBy called communityId = ${communityId}.`);
    }
    const response = await communityApi.show();

    if (response.ok) {
      const data = await response.json();
      onDataLoadSuccess(data);
    } else {
      // Add error handling. May be show toast message
    }
  };

  const refresh = async () => {
    setStatus(REFRESHING);
    await loadData();
  };

  const communityVisibilityText = () => (isPrivate ? "Private" : "Public");

  const isDataLoaded = () => status === LOADED;
  const isDataLoading = () => status === LOADING;
  const isDataRefreshing = () => status === REFRESHING;

  const value = {
    status,
    record,
    actions: { loadData, refresh },
    helpers: {
      isDataLoading,
      isDataRefreshing,
      isDataLoaded,
      communityVisibilityText,
    },
  };
  return (
    <CurrentCommunityContext.Provider value={value}>
      {children}
    </CurrentCommunityContext.Provider>
  );
};

export const useCurrentCommunityContext = () =>
  useContext(CurrentCommunityContext);
