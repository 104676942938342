import type { ApiResource } from "@circle-react/components/SettingsApp/Moderation/useModerationApi";
import { absolutePath } from "./absolutePath";

export const flaggedContent = {
  index: ({ params = {} }: ApiResource = {}) =>
    absolutePath("flagged_contents", params),

  show: ({ id }: ApiResource) => absolutePath(`flagged_contents/${id}`),

  approve: ({ id }: ApiResource) =>
    absolutePath(`flagged_contents/${id}/approve`),

  reject: ({ id }: ApiResource) =>
    absolutePath(`flagged_contents/${id}/reject`),

  create: () => absolutePath("/flagged_contents"),

  unaddressedFlaggedContentableCount: () =>
    absolutePath("/flagged_contents/unaddressed_flagged_contentable_count"),
};
