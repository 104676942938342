import { absolutePath } from "./absolutePath";

const emptyArgs = {
  params: {},
};

export const communityBot = {
  snippets: {
    index: ({ params = {} } = {}) =>
      absolutePath("community_bot/snippets", params),
    show: ({ id } = {}) => absolutePath(`community_bot/snippets/${id}`),
    create: ({ params } = emptyArgs) =>
      absolutePath(`community_bot/snippets`, params),
    update: ({ id } = {}) => absolutePath(`community_bot/snippets/${id}`),
    destroy: ({ id } = {}) => absolutePath(`community_bot/snippets/${id}`),
  },
  spaces: {
    index: ({ params = {} } = {}) =>
      absolutePath("community_bot/spaces", params),
  },
};
