import { useMemo } from "react";

interface UseLocalStorage<T> {
  getValue: () => T | undefined | null;
  setValue: (value: T) => void;
  resetValue: () => void;
}

export const useLocalStorage = <T>(key: string): UseLocalStorage<T> =>
  useMemo(
    () =>
      ({
        getValue: () => {
          try {
            return safeParseJSON<T>(localStorage.getItem(key));
          } catch (error) {
            console.error("LocalStorage is not accessible", error);
            return null;
          }
        },
        setValue: (value: T) => {
          try {
            return localStorage.setItem(key, JSON.stringify(value));
          } catch (error) {
            console.error("LocalStorage is not accessible", error);
          }
        },
        resetValue: () => {
          try {
            return localStorage.removeItem(key);
          } catch (error) {
            console.error("LocalStorage is not accessible", error);
          }
        },
      }) as const,
    [key],
  );

const safeParseJSON = <T>(value: string | null): T | null | undefined => {
  try {
    if (value === null) {
      return null;
    }

    return JSON.parse(value);
  } catch (e) {
    console.error("Parsing JSON failed", e);
    return undefined;
  }
};
