import { absolutePath } from "./absolutePath";

export const comments = {
  index: ({ postId, params = {} } = {}) =>
    absolutePath(`posts/${postId}/comments`, params),
  create: ({ postId, params = {} } = {}) =>
    absolutePath(`posts/${postId}/comments`, params),
  update: ({ postId, id, params = {} } = {}) =>
    absolutePath(`posts/${postId}/comments/${id}`, params),
  destroy: ({ postId, id, params = {} } = {}) =>
    absolutePath(`posts/${postId}/comments/${id}`, params),
  commentHierarchy: ({ postId, id, params = {} } = {}) =>
    absolutePath(`posts/${postId}/comments/${id}/comment_hierarchy`, params),
  userLikes: ({ id, params = {} }) =>
    absolutePath(`comments/${id}/user_likes`, params),
};
