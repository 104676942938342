import type { ReactNode } from "react";
import { useEffect } from "react";
import type { ThemeObject } from "@circle-react/types/Theme";
import type { ThemeContextProps } from "../ThemeContext";
import { ThemeContext } from "../ThemeContext";
import { APPEARANCE } from "../themes";
import { useThemeState } from "./useThemeState";

export interface ControlledThemeProviderProps {
  children: ReactNode;
  theme: ThemeObject;
  selector?: string;
}

export const ControlledThemeProvider = ({
  children,
  theme,
  selector = "",
}: ControlledThemeProviderProps) => {
  const { default_appearance = APPEARANCE.LIGHT }: ThemeObject = theme;
  const themeObject: ThemeContextProps = useThemeState(theme, selector);
  const { setAppearance, currentAppearance } = themeObject;

  useEffect(() => {
    if (default_appearance !== currentAppearance) {
      setAppearance?.(default_appearance);
    }
  }, [setAppearance, currentAppearance, default_appearance]);

  return (
    <ThemeContext.Provider value={themeObject}>
      {children}
    </ThemeContext.Provider>
  );
};
