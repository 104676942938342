import { colord } from "colord";
import type { ThemeObject } from "@circle-react/types/Theme";
import { APPEARANCE, THEME_MAP, VARIABLE_MAP } from "./themes";

export interface UpdateThemeProps {
  theme: ThemeObject;
  documentElement?: HTMLElement;
  selector?: string;
}

const brandCssVarName = "--brand";

export const updateTheme = ({
  theme,
  documentElement = window.document.documentElement,
  selector = "",
}: // eslint-disable-next-line sonarjs/cognitive-complexity -- Provisory until having brand text color customizable
UpdateThemeProps) => {
  const {
    current_appearance: currentAppearance = APPEARANCE.LIGHT,
    custom_colors: customColors = {},
    current_theme: currentTheme = "default",
    brand_color: brandColor = {
      light: "",
      dark: "",
    },
    brand_text_color: brandTextColor = {
      light: "",
      dark: "",
    },
  }: ThemeObject = theme;

  const hasBrandColor = brandColor?.light && brandColor?.dark;
  const hasBrandTextColor = brandTextColor?.light && brandTextColor?.dark;

  const themeVariables: any =
    THEME_MAP?.[currentTheme]?.[currentAppearance] ||
    THEME_MAP.default?.[currentAppearance] ||
    THEME_MAP.default.light;

  const defaultThemeVariables: any =
    THEME_MAP.default[currentAppearance] || THEME_MAP.default.light;

  const cssVariables = new Map<string, string>();

  Object.entries<any>(themeVariables)
    .filter(([key]) => key !== brandCssVarName)
    .forEach(([key, value]) => {
      cssVariables.set(key, value);
    });

  if (currentTheme === "custom" && customColors[currentAppearance]) {
    Object.entries<any>(customColors[currentAppearance]).forEach(
      ([key, value]) => {
        const cssVarKey = VARIABLE_MAP[key];
        if (value) {
          cssVariables.set(cssVarKey, String(value));
        } else if (defaultThemeVariables[cssVarKey]) {
          cssVariables.set(cssVarKey, String(defaultThemeVariables[cssVarKey]));
        }
      },
    );
  }

  if (hasBrandColor) {
    const color = colord(brandColor[currentAppearance]);
    const lightenBrandColor = color.lighten(0.1).toHex();
    const darkenBrandColor = color.darken(0.1).toHex();
    cssVariables.set(brandCssVarName, color.toHex());

    cssVariables.set(
      "--brand-button-hover",
      color.isDark() ? lightenBrandColor : darkenBrandColor,
    );
    cssVariables.set("--brand-light", lightenBrandColor);
    cssVariables.set("--brand-dark", darkenBrandColor);
  }

  if (hasBrandTextColor) {
    cssVariables.set("--brand-button-text", brandTextColor[currentAppearance]);
  }

  let element = documentElement;

  if (selector) {
    try {
      const selected: HTMLElement | null = document.querySelector(selector);
      if (selected) {
        element = selected;
      }
    } catch (error) {
      console.error(error);
      element = documentElement;
    }
  }

  cssVariables.forEach((value, key) => {
    element.style.setProperty(key, value);
  });

  if (currentAppearance === APPEARANCE.DARK) {
    element.classList.add("dark");
  } else {
    element.classList.remove("dark");
  }
};
