import { BrowserRouter } from "react-router-dom";
import { NavigationTracker } from "@circle-react/components/AnalyticsTracker/NavigationTracker";
import { SupportWidgetContextProvider } from "@circle-react/components/SupportWidget/SupportWidgetContext";
import { LayoutPartialsContextProvider } from "@circle-react/contexts/layoutPartialsContext";
import { SpaceGroupsContextProvider } from "@circle-react/contexts/spaceGroupsContext";
import { SpacesContextProvider } from "@circle-react/contexts/spacesContext";
import { ToastProvider } from "@circle-react-uikit/ToastV2";
import { AppContextProvider } from "../AppContext";
import { QueryClientProvider } from "../QueryClient";
import { ThemeProvider } from "../Theme";
import { RouteChangeEventWrapper } from "./RouteChangeEventWrapper";

// "backendProps" comes from app/views/shared/_react_app.html.erb
export const MainAppProviders = ({ children, backendProps = {} }) => {
  const {
    community_theme_settings = {},
    brand_color = {
      light: "",
      dark: "",
    },
    brand_text_color = {
      light: "",
      dark: "",
    },
  } = backendProps;

  const theme = {
    ...community_theme_settings,
    brand_color,
    brand_text_color,
  };

  return (
    <QueryClientProvider>
      <ThemeProvider theme={theme}>
        <AppContextProvider backendProps={backendProps}>
          <LayoutPartialsContextProvider>
            <SpaceGroupsContextProvider>
              <SpacesContextProvider includeSidebar>
                <ToastProvider>
                  <SupportWidgetContextProvider>
                    <BrowserRouter>
                      <RouteChangeEventWrapper>
                        <NavigationTracker>{children}</NavigationTracker>
                      </RouteChangeEventWrapper>
                    </BrowserRouter>
                  </SupportWidgetContextProvider>
                </ToastProvider>
              </SpacesContextProvider>
            </SpaceGroupsContextProvider>
          </LayoutPartialsContextProvider>
        </AppContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
