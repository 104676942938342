import { createContext, useContext, useMemo } from "react";
import type { ThemeOptionsCustom } from "@circle-react/providers/Theme";
import type { Appearance } from "@circle-react/providers/Theme/themes/constants";
import type {
  AppearanceColor,
  ThemeCustomColors,
  ThemeObject,
} from "@circle-react/types/Theme";

export interface ThemeContextProps {
  brandColor: AppearanceColor;
  brandTextColor: AppearanceColor;
  currentAppearance: Appearance;
  currentTheme: ThemeOptionsCustom;
  customColors: ThemeCustomColors;
  isCustomTheme: boolean;
  isDarkAppearance?: boolean;
  isLightAppearance?: boolean;
  setAppearance?: (appearance: Appearance) => void;
  toggleAppearance?: () => void;
}

export const ThemeContext = createContext<ThemeContextProps | null>(null);
ThemeContext.displayName = "ThemeContext";

export const useThemeContext = () => {
  const context: ThemeContextProps | null = useContext(ThemeContext);

  if (context === null) {
    throw new Error("useThemeContext must be used within a ThemeProvider");
  }

  return context;
};

export const useThemeObject = () => {
  const {
    currentTheme,
    customColors,
    currentAppearance,
    isCustomTheme,
    brandColor,
    brandTextColor,
  } = useThemeContext();

  return useMemo<ThemeObject>(
    () => ({
      current_theme: currentTheme,
      custom_colors: customColors,
      default_appearance: currentAppearance,
      is_custom: isCustomTheme,
      brand_color: brandColor,
      brand_text_color: brandTextColor,
    }),
    [
      currentTheme,
      customColors,
      currentAppearance,
      isCustomTheme,
      brandColor,
      brandTextColor,
    ],
  );
};
