import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const HIGH_CONTRAST_COLORS: PartialTheme = {
  light: {
    "--brand": "#1B18B7",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FFFFFF",
    "--color-community-header-text": "#2C2C2E",
    "--color-community-sidebar-background": "#FFFFFF",
    "--color-community-sidebar-text": "#2C2C2E",
    "--color-community-sidebar-active-background": "#1B18B7",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#EBEBEB",
    "--color-community-sidebar-hover-background": "#EBEBEB",
    "--color-online-background": "#F03964",
    "--color-community-sidebar-badge-background": "#211CF9",
    "--color-community-header-active-text": "#2C2C2E",
    "--color-community-header-active-background": "#EBEBEB",
  },
  dark: {
    "--brand": "#8583FF",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#22252D",
    "--color-community-header-text": "#ECE7F6",
    "--color-community-sidebar-background": "#22252D",
    "--color-community-sidebar-text": "#ECE7F6",
    "--color-community-sidebar-active-background": "#8583FF",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#494949",
    "--color-community-sidebar-hover-background": "#494949",
    "--color-online-background": "#F03964",
    "--color-community-sidebar-badge-background": "#7D4183",
    "--color-community-header-active-text": "#ECE7F6",
    "--color-community-header-active-background": "#494949",
  },
} as const;

export const HIGH_CONTRAST_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...HIGH_CONTRAST_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...HIGH_CONTRAST_COLORS.dark,
  },
} as const;
