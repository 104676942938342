import {
  removeOpengraphImagePath,
  resetCommunityWelcomeBannerPreferencePath,
} from "@/react/helpers/urlHelpers";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const landingPageSettingsApi = {
  get: async () => reactQueryGet(internalApi.landingPageSettings.index()),

  update: payload =>
    reactQueryPost(internalApi.landingPageSettings.update(), payload),

  deleteOpenGraphImage: uuid =>
    reactQueryDelete(removeOpengraphImagePath(uuid)),

  resetBannerPreferences: () =>
    reactQueryPatch(resetCommunityWelcomeBannerPreferencePath()),
};
