import { absolutePath } from "./absolutePath";

export const profile = {
  show: ({ memberPublicId, params = {} } = {}) =>
    absolutePath(`profiles/${memberPublicId}`, params),
  update: ({ memberPublicId, params = {} } = {}) =>
    absolutePath(`profiles/${memberPublicId}`, params),
  spaces: ({ memberPublicId, params = {} } = {}) =>
    absolutePath(`profiles/${memberPublicId}/spaces`, params),
  posts: ({ memberPublicId, params = {} } = {}) =>
    absolutePath(`profiles/${memberPublicId}/posts`, params),
  comments: ({ memberPublicId, params = {} } = {}) =>
    absolutePath(`profiles/${memberPublicId}/comments`, params),
  signUpProfile: () => absolutePath(`signup/profile`),
  leadShow: ({ leadPublicId, params = {} } = {}) =>
    absolutePath(`community_leads/${leadPublicId}`, params),
  leadUpdate: ({ leadPublicId, params = {} } = {}) =>
    absolutePath(`community_leads/${leadPublicId}`, params),
};
