import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { find } from "lodash";
import { getIsLocalStorageCacheEnabled } from "@/react/helpers/communityHelpers";
import { useLocalStorage } from "@/react/hooks/utils/useLocalStorage";
import { LOCALSTORAGE_KEYS } from "@circle-react/config/Settings";
import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";
import { LOADED, LOADING, REFRESHING } from "./loadingStatuses";

// In long run we may have multiple states for status: loading, reloading, etc.

const initialState = {
  status: LOADING,
  records: [],
  actions: [], // Action modify the state
  helpers: [], // helper read the state to parse/extract information
};

const SpaceGroupsContext = createContext(initialState);
SpaceGroupsContext.displayName = "SpaceGroupsContext";

export interface SpaceGroupsContextProviderProps {
  children: ReactNode;
  spaceGroups?: any;
}

export const SpaceGroupsContextProvider = ({
  children,
  spaceGroups,
}: SpaceGroupsContextProviderProps) => {
  const [status, setStatus] = useState(LOADING);
  const [records, setRecords] = useState([]);
  const { getValue: getLocalStorageValue, setValue: setLocalStorageValue } =
    useLocalStorage<any>(LOCALSTORAGE_KEYS.SpaceGroupsContextProvider);
  const onDataLoadSuccess = (data: any) => {
    setRecords(data.records);
    setStatus(LOADED);
    setLocalStorageValue(data.records);
  };

  useEffect(() => {
    if (spaceGroups) {
      setRecords(spaceGroups);
      setStatus(LOADED);
    } else {
      if (getIsLocalStorageCacheEnabled()) {
        const localStorageSpaceGroups = getLocalStorageValue();
        if (localStorageSpaceGroups && localStorageSpaceGroups.length > 0) {
          setRecords(localStorageSpaceGroups);
          setStatus(LOADED);
        }
      }
      void loadData();
    }
  }, []);

  const loadData = async () => {
    const response = await fetchRequest(internalApi.spaceGroups.index(), {
      method: "GET",
    });

    if (response.ok) {
      onDataLoadSuccess(await response.json());
    } else {
      // Add error handling. May be show toast message
    }
  };

  const refresh = () => {
    setStatus(REFRESHING);
    void loadData();
  };

  const isDataLoaded = () => status === LOADED;
  const isDataLoading = () => status === LOADING;
  const isDataRefreshing = () => status === REFRESHING;

  const findById = (id: any) => findBy({ id });

  // uses: https://lodash.com/docs/4.17.15#find
  const findBy = (conditions = {}) => {
    if (!isDataLoaded()) {
      console.warn(`findBy called when status = ${status}.`);
    }
    return find(records, conditions);
  };

  const value: any = {
    status,
    records,
    actions: { loadData, refresh },
    isLoading: isDataLoading(),
    helpers: {
      isDataLoading,
      isDataRefreshing,
      isDataLoaded,
      findById,
      findBy,
    },
  };
  return (
    <SpaceGroupsContext.Provider value={value}>
      {children}
    </SpaceGroupsContext.Provider>
  );
};

export const useSpaceGroupsContext = (): any => useContext(SpaceGroupsContext);
