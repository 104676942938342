import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const SAGE_COLORS: PartialTheme = {
  light: {
    "--brand": "#555E33",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#C1CEA5",
    "--color-community-header-text": "#29160C",
    "--color-community-sidebar-background": "#C1CEA5",
    "--color-community-sidebar-text": "#29160C",
    "--color-community-sidebar-active-background": "#555E33",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#A8B58A",
    "--color-community-sidebar-hover-background": "#A8B58A",
    "--color-online-background": "#C68152",
    "--color-community-sidebar-badge-background": "#BC8661",
    "--color-community-header-active-text": "#29160C",
    "--color-community-header-active-background": "#A8B58A",
  },
  dark: {
    "--brand": "#C1CEA5",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#C1CEA5",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#C1CEA5",
    "--color-community-sidebar-active-background": "#C1CEA5",
    "--color-community-active-item-text-color": "#000000",
    "--color-community-header-hover-background": "#4D5242",
    "--color-community-sidebar-hover-background": "#4D5242",
    "--color-online-background": "#C68152",
    "--color-community-sidebar-badge-background": "#BC8661",
    "--color-community-header-active-text": "#C1CEA5",
    "--color-community-header-active-background": "#4D5242",
  },
};

export const SAGE_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...SAGE_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...SAGE_COLORS.dark,
  },
};
