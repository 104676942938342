import { absolutePath } from "./absolutePath";

export const communityInbox = {
  index: ({ params = {} } = {}) =>
    absolutePath(`community_inbox/chats`, params),
  show: ({ id }: { id: number }) => absolutePath(`community_inbox/chats/${id}`),
  fetchMessages: ({ id, params = {} }: { id: number; params: any }) =>
    absolutePath(`community_inbox/chats/${id}/messages`, params),
  createMessage: ({ id }: { id: number }) =>
    absolutePath(`community_inbox/chats/${id}/messages`),
};
