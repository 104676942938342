import { absolutePath } from "./absolutePath";

interface ShowParams {
  quizId: number;
  id: number;
  params?: {
    for_admin_review: boolean;
  };
}
interface CreateParams {
  quizId: number;
}
export const quizAttempts = {
  show: ({ quizId, id, params }: ShowParams) =>
    absolutePath(`quizzes/${quizId}/attempts/${id}`, params),
  create: ({ quizId }: CreateParams) =>
    absolutePath(`quizzes/${quizId}/attempts`),
  update: ({ quizId, id }: ShowParams) =>
    absolutePath(`quizzes/${quizId}/attempts/${id}`),
};
