import { absolutePath } from "./absolutePath";

export const communityPlanAddons = {
  index: ({ params = {} } = {}) =>
    absolutePath("community_plan_addons", params),
  create: ({ params = {} } = {}) =>
    absolutePath("community_plan_addons", params),
  show: ({ addonId, params = {} } = {}) =>
    absolutePath(`community_plan_addons/${addonId}`, params),
};
