import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const get = async () =>
  fetchRequest(internalApi.settings.home.index(), {
    method: "GET",
  });

const update = async settings =>
  fetchRequest(internalApi.settings.home.update(), {
    method: "POST",
    body: settings,
    credentials: "same-origin",
    mode: "no-cors",
  });

const resetWelcomeBanner = async () =>
  fetchRequest(internalApi.settings.landingPage.welcomeBanner.index(), {
    method: "PATCH",
  });

const deleteAttachedFiles = async field_name =>
  fetchRequest(internalApi.settings.attachedFiles.destroy(), {
    method: "DELETE",
    body: JSON.stringify({ field_name }),
  });

const deleteOpenGraphImage = async uuid =>
  fetchRequest(internalApi.settings.openGraphImage.destroy({ uuid }), {
    method: "DELETE",
  });

export const homeSettingsApi = {
  get,
  update,
  deleteAttachedFiles,
  deleteOpenGraphImage,
  resetWelcomeBanner,
};
