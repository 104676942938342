export const APPEARANCE = {
  LIGHT: "light",
  DARK: "dark",
} as const;

export type Appearance = (typeof APPEARANCE)[keyof typeof APPEARANCE];

export const VARIABLE_MAP: any = {
  sidebar_background_color: "--color-community-sidebar-background",
  header_background_color: "--color-community-header-background",
  header_text_color: "--color-community-header-text",
  header_active_text_color: "--color-community-header-active-text",
  header_active_background_color: "--color-community-header-active-background",
  link_color: "--color-text-link",
  sidebar_text_color: "--color-community-sidebar-text",
  active_item_color: "--color-community-sidebar-active-background",
  active_item_text_color: "--color-community-active-item-text-color",
  sidebar_hover_color: "--color-community-sidebar-hover-background",
  online_indicator_color: "--color-online-background",
  mention_badge_color: "--color-community-sidebar-badge-background",
};

export type VariableOptions = keyof typeof VARIABLE_MAP;
