interface RoomSlug {
  roomSlug: string;
}

export const liveStreamPaths = {
  room: ({ roomSlug }: RoomSlug) => `/live/${roomSlug}`,
  mobileRoom: ({ roomSlug }: RoomSlug) => `/live/${roomSlug}/mobile`,
  publicRoom: ({ roomSlug }: RoomSlug) => `/live/${roomSlug}/public`,
  notSupported: ({ roomSlug }: RoomSlug) => `/live/${roomSlug}/not_supported`,
};
