import { absolutePath } from "@/react/helpers/urlHelpers/internalApi/absolutePath";

interface ParamsProps {
  params?: any;
}

export const invitationLinks = {
  index: (props?: ParamsProps) =>
    absolutePath("admin/invitation_links", props?.params),

  get: ({ id }: any) => absolutePath(`admin/invitation_links/${id}`),

  create: (props?: ParamsProps) =>
    absolutePath("admin/invitation_links", props?.params),

  update: ({ id }: any) => absolutePath(`admin/invitation_links/${id}`),

  revoke: ({ id }: any) => absolutePath(`admin/invitation_links/${id}/revoke`),

  reactivate: ({ id }: any) =>
    absolutePath(`admin/invitation_links/${id}/reactivate`),

  destroy: ({ id }: any) => absolutePath(`admin/invitation_links/${id}`),
};
