export interface RouteWithPreviousLocation {
  pathname: string;
  hash?: string;
  search: string;
  state: {
    from: {
      path: string;
      query: string;
    };
  };
}

export interface RouteWithPreviousLocationParams {
  url: string;
  hash?: string;
  search?: string;
}

export const getRouteWithPrevLocation = ({
  url,
  hash,
  search = "",
}: RouteWithPreviousLocationParams): RouteWithPreviousLocation => ({
  pathname: url,
  hash,
  search,
  state: {
    from: {
      path: window.location.pathname,
      query: window.location.search,
    },
  },
});
