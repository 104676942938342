import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const ZEN_MIND_COLORS: PartialTheme = {
  light: {
    "--brand": "#9BA77E",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#FFFFFD",
    "--color-community-header-background": "#FFFFFD",
    "--color-community-sidebar-text": "#29160C",
    "--color-community-header-text": "#29160C",
    "--color-community-sidebar-active-background": "#9BA77E",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#F7F3EE",
    "--color-community-sidebar-hover-background": "#F7F3EE",
    "--color-online-background": "#C68152",
    "--color-community-sidebar-badge-background": "#BC8661",
    "--color-community-header-active-text": "#29160C",
    "--color-community-header-active-background": "#F7F3EE",
  },
  dark: {
    "--brand": "#9BA77E",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#25242B",
    "--color-community-header-background": "#25242B",
    "--color-community-sidebar-text": "#FFFFFF",
    "--color-community-header-text": "#FFFFFF",
    "--color-community-sidebar-active-background": "#9BA77E",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#43464C",
    "--color-community-sidebar-hover-background": "#43464C",
    "--color-online-background": "#C68152",
    "--color-community-sidebar-badge-background": "#BC8661",
    "--color-community-header-active-text": "#FFFFFF",
    "--color-community-header-active-background": "#43464C",
  },
};

export const ZEN_MIND_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...ZEN_MIND_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...ZEN_MIND_COLORS.dark,
  },
};
