import {
  fetchRequest,
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
  reactQueryPut,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const show = () =>
  fetchRequest(internalApi.communities.show(), {
    method: "GET",
  });

const update = payload =>
  reactQueryPut(internalApi.communities.update(), payload);

const communitySettings = () =>
  fetchRequest(internalApi.communities.communitySettings.index(), {
    method: "GET",
  });

const getOnboarding = () =>
  reactQueryGet(internalApi.communities.onboarding.show());

const updateOnboardingSettings = payload =>
  reactQueryPatch(
    internalApi.communities.onboarding_settings.update(),
    payload,
  );

const completeOnboardingStep = payload =>
  reactQueryPut(internalApi.communities.onboarding.update(), payload);

const getCustomInvitationEmail = () =>
  reactQueryGet(internalApi.communities.customInvitationEmail.show());

const updateCustomInvitationEmail = payload =>
  reactQueryPatch(
    internalApi.communities.customInvitationEmail.update(),
    payload,
  );

const getTemplates = communityId =>
  reactQueryGet(internalApi.communities.templates.index({ communityId }));

const assignTemplate = (communityId, payload) =>
  reactQueryPost(
    internalApi.communities.templates.create({ communityId }),
    payload,
  );

const submitQuestions = (communityId, payload) =>
  reactQueryPost(
    internalApi.communities.questions.create({ communityId }),
    payload,
  );

const createCommunity = payload =>
  reactQueryPost(internalApi.communities.create(), payload);

const getCustomDomain = () => reactQueryGet(internalApi.customDomain.show());

const updateCustomDomain = payload =>
  reactQueryPost(internalApi.customDomain.update(), payload);

const removeCustomDomain = () =>
  reactQueryDelete(internalApi.customDomain.destroy());

const getHomeSettings = () =>
  reactQueryGet(internalApi.communities.homeSettings.show());

const getLandingPageSettings = () =>
  reactQueryGet(internalApi.landingPageSettings.show());

const updateLandingPageSettings = () =>
  reactQueryPost(internalApi.landingPageSettings.update());

export const communityApi = {
  show,
  update,
  communitySettings,
  getOnboarding,
  updateOnboardingSettings,
  getCustomInvitationEmail,
  updateCustomInvitationEmail,
  completeOnboardingStep,
  getTemplates,
  assignTemplate,
  submitQuestions,
  createCommunity,
  getCustomDomain,
  updateCustomDomain,
  removeCustomDomain,
  getHomeSettings,
  getLandingPageSettings,
  updateLandingPageSettings,
};
