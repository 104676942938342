import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const NIGHTSHADE_COLORS: PartialTheme = {
  light: {
    "--brand": "#617276",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#25242B",
    "--color-community-header-text": "#B1AFBD",
    "--color-community-sidebar-background": "#25242B",
    "--color-community-sidebar-text": "#B1AFBD",
    "--color-community-sidebar-active-background": "#617276",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#35333D",
    "--color-community-sidebar-hover-background": "#35333D",
    "--color-online-background": "#B82C68",
    "--color-community-sidebar-badge-background": "#03081A",
    "--color-community-header-active-text": "#B1AFBD",
    "--color-community-header-active-background": "#35333D",
  },
  dark: {
    "--brand": "#617276",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#25242B",
    "--color-community-header-text": "#B1AFBD",
    "--color-community-sidebar-background": "#25242B",
    "--color-community-sidebar-text": "#B1AFBD",
    "--color-community-sidebar-active-background": "#617276",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#35333D",
    "--color-community-sidebar-hover-background": "#35333D",
    "--color-online-background": "#B82C68",
    "--color-community-sidebar-badge-background": "#03081A",
    "--color-community-header-active-text": "#B1AFBD",
    "--color-community-header-active-background": "#35333D",
  },
};

export const NIGHTSHADE_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...NIGHTSHADE_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...NIGHTSHADE_COLORS.dark,
  },
};
