import { absolutePath } from "./absolutePath";

export const communities = {
  create: ({ params = {} } = {}) => absolutePath("communities", params),
  show: ({ params = {} } = {}) => absolutePath("communities", params),
  update: ({ params = {} } = {}) => absolutePath("communities", params),
  communityAiSettings: {
    show: ({ params = {} } = {}) =>
      absolutePath("community_ai_settings", params),
    languages: ({ params = {} } = {}) =>
      absolutePath("community_ai_settings/languages", params),
    update: ({ params = {} } = {}) =>
      absolutePath("community_ai_settings", params),
  },
  communitySettings: {
    index: ({ params = {} } = {}) => absolutePath("community_settings", params),
    update: ({ communitySettingId, params = {} } = {}) =>
      absolutePath(`community_settings/${communitySettingId}`, params),
  },
  communityLinks: {
    create: () => absolutePath("community_links"),
    update: ({ communityLinkId }) =>
      absolutePath(`community_links/${communityLinkId}`),
    delete: ({ communityLinkId }) =>
      absolutePath(`community_links/${communityLinkId}`),
    reorder: () => absolutePath("community_links/reorder"),
  },
  onboarding: {
    show: ({ params = {} } = {}) =>
      absolutePath("communities/onboarding", params),
    update: ({ params = {} } = {}) =>
      absolutePath("communities/onboarding", params),
  },
  onboarding_settings: {
    update: ({ params = {} } = {}) =>
      absolutePath("community_onboarding_settings", params),
  },
  customInvitationEmail: {
    show: ({ params = {} } = {}) =>
      absolutePath("communities/custom_invitation_email", params),
    update: ({ params = {} } = {}) =>
      absolutePath("communities/custom_invitation_email", params),
  },
  templates: {
    index: ({ communityId, params = {} } = {}) =>
      absolutePath(`communities/${communityId}/templates`, params),
    create: ({ communityId, params = {} } = {}) =>
      absolutePath(`communities/${communityId}/templates`, params),
  },
  questions: {
    create: ({ communityId, params = {} } = {}) =>
      absolutePath(`communities/${communityId}/questions`, params),
  },
  plans: {
    show: ({ forceFromSubscription = false }) =>
      absolutePath(`communities/plans`, {
        force_from_subscription: forceFromSubscription,
      }),
  },
};
