import {
  reactQueryDelete,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const pinChatRoom = (uuid: string) =>
  reactQueryPost(internalApi.pinnedChatRoom.create({ uuid }));

const unpinChatRoom = (uuid: string) =>
  reactQueryDelete(internalApi.pinnedChatRoom.destroy({ uuid }));

export const pinnedChatRoomApi = {
  pinChatRoom,
  unpinChatRoom,
};
