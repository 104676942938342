import { parseISO } from "date-fns";
import { get, isEmpty } from "lodash";
import {
  isPrivateCommunity,
  isPublicCommunity,
  isTrialCommunity,
} from "@/react/helpers/communityHelpers";
import { postsPath, settingsPath } from "@/react/helpers/urlHelpers";
import {
  dateFormats,
  formattedDateTime,
} from "@circle-react/helpers/dateTimeHelpers/formattedDateTime";
import {
  isPrivateSpace,
  isPublicSpace,
  userIsAdminOrModeratorOfSpace,
} from "./spaceHelpers";

const CARD_VIEW = "cards";
const LIST_VIEW = "list";
const BASIC_TYPE = "Posts::Basic";
const EVENT_TYPE = "Posts::Event";
const IMAGE_TYPE = "Posts::Image";

export const showTitleInput = (space: any) => {
  // Return 'true' to show the cover image field if modal is opened on the homepage
  if (!space) {
    return true;
  }
  return userIsAdminOrModeratorOfSpace(space) || !space.hide_post_settings;
};

export const showCoverImageSelector = (space: any) => {
  // Return 'true' to show the cover image field if modal is opened on the homepage
  if (!space) {
    return true;
  }
  return (
    userIsAdminOrModeratorOfSpace(space) || !space.disable_member_post_covers
  );
};

export const isDisplayCardView = (space: any) => {
  space = space || {};
  return space.display_view === CARD_VIEW;
};

export const isDisplayListView = (space: any) =>
  space.display_view === LIST_VIEW;

export const eventLocation = (inPersonLocation: any) => {
  if (!inPersonLocation) {
    return null;
  }

  try {
    return JSON.parse(inPersonLocation);
  } catch (err) {
    return { formatted_address: inPersonLocation };
  }
};

export const canManagePost = (post: any) => !!post?.policies?.can_manage_post;
export const canUpdatePost = (post: any) => !!post?.policies?.can_update_post;
export const canDuplicatePost = (post: any) =>
  !!post?.policies?.can_duplicate_post;
export const canDestroyPost = (post: any) => !!post?.policies?.can_destroy_post;
export const canReportPost = (post: any) => !!post?.policies?.can_report_post;

export const isSharedPostView = (post: any, { postSlugInUrl }: any) =>
  Boolean(postSlugInUrl === post?.share_uid && post?.share_post_enabled);

export const showPostActions = (
  post: any,
  { currentUser, postSlugInUrl }: any,
) => !!currentUser && !isSharedPostView(post, { postSlugInUrl });

export const isBasicPost = (post: any) => post.type === BASIC_TYPE;
export const isEventPost = (post: any) => post?.type === EVENT_TYPE;
export const isImagePost = (post: any) => post.type === IMAGE_TYPE;
export const noTitleAndFlagged = (post: any) => post.type === EVENT_TYPE;

export const canSharePost = (post: any, community: any, space: any) => {
  if (!space || !community || !post) return false;
  if (!(isBasicPost(post) || isEventPost(post) || isImagePost(post)))
    return false;
  if (!isPrivateSpace(space) && !isPrivateCommunity(community)) return true;
  if (isPublicSpace(space) && isPublicCommunity(community)) return true;

  return userIsAdminOrModeratorOfSpace(space) && !isTrialCommunity(community);
};

export const POST_STATUS_PUBLISHED = "published";
export const POST_STATUS_SCHEDULED = "scheduled";
export const POST_STATUS_DRAFT = "draft";

export const isPublishedPost = (post: any) =>
  post.status === POST_STATUS_PUBLISHED;
export const isScheduledPost = (post: any) =>
  post.status === POST_STATUS_SCHEDULED;
export const isDraftPost = (post: any) => post.status === POST_STATUS_DRAFT;

export const isUsingTiptapEditor = (post: any) => post.editor === "tiptap";
export const isUsingTrixEditor = (post: any) => !isUsingTiptapEditor(post);

export const isPostBodyEmpty = (post: any) => {
  if (get(post, "body_trix_content") || get(post, "internal_custom_html")) {
    return false;
  }

  const tiptapContent = get(post, "tiptap_body.body.content", []);

  return isEmpty(tiptapContent);
};

export const isPostMetaHidden = (post: any) => Boolean(post?.hide_meta_info);

export const afterSaveRedirectPath = ({ post, editMode = false }: any) => {
  if (isPublishedPost(post)) {
    return postsPath({
      spaceSlug: post.space_slug,
      slug: post.slug,
      params: editMode ? { edit: true } : {},
    });
  }
  return settingsPath.postsDashboard.index({ params: { status: post.status } });
};

export const getDetailedTimestamp = (timestamp: any) => {
  const dateTime = parseISO(timestamp);
  return formattedDateTime({
    dateTime,
    format: dateFormats.long_date_at_short_time,
  });
};
