import { absolutePath } from "./absolutePath";

export const search = {
  communityMembers: {
    index: ({ params = {} }) =>
      absolutePath("search/community_members", params),
    /** Multiple instances of filters - AND/OR */
    indexV2: ({ params = {} }) =>
      absolutePath("v2/search/community_members", params, {
        arrayFormat: "indices",
      }),
  },
  spaces: {
    index: ({ params = {} }) => absolutePath("search/spaces", params),
  },
  communities: {
    index: ({ params = {} }) => absolutePath("search/communities", params),
  },
  paywalls: {
    index: ({ params = {} }) => absolutePath("search/paywalls", params),
  },
  paywallsAffiliates: {
    index: ({ params = {} }) =>
      absolutePath("search/paywalls/affiliates", params),
  },
  paywallsAffiliatesCommissions: {
    index: (params = {}) =>
      absolutePath("search/paywalls/affiliates/commissions", params),
  },
};

export const searchV2 = {
  basic: {
    index: ({ params = {} }) =>
      absolutePath("search/community_members.json", params),
  },
  advanced: {
    index: ({ params = {} }) => absolutePath("search/advanced_search", params),
  },
  count: {
    index: ({ params = {} }) =>
      absolutePath("search/advanced_search/count.json", params),
  },
  advancedCount: {
    index: ({ params = {} }) =>
      absolutePath("search/community_members.json", params),
  },
};
