import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { find } from "lodash";
import { getIsLocalStorageCacheEnabled } from "@/react/helpers/communityHelpers";
import { useLocalStorage } from "@/react/hooks/utils/useLocalStorage";
import { usePunditUserContext } from "@circle-react/contexts";
import { spaceApi } from "../api";
import {
  canCreateEventInSpace,
  canCreatePostInBasicOrImageSpace,
  canCreatePostInBasicSpace,
  canCreatePostInImageSpace,
  canCreatePostInSpace,
  canViewSpaceContent,
} from "../helpers/spaceHelpers";
import { LOADED, LOADING, REFRESHING } from "./loadingStatuses";

// In long run we may have multiple states for status: loading, reloading, etc.

const initialState = {
  status: LOADING,
  records: [],
  actions: [], // Action modify the state
  helpers: [], // helper read the state to parse/extract information
};

const SpacesContext = createContext(initialState);
SpacesContext.displayName = "SpacesContext";

export interface SpacesContextProviderProps {
  children: ReactNode;
  includeSidebar?: boolean;
  spaces?: any;
}

export const SpacesContextProvider = ({
  children,
  includeSidebar,
  spaces,
}: SpacesContextProviderProps) => {
  const [status, setStatus] = useState(LOADING);
  const [records, setRecords] = useState<any>([]);
  const [sidebarHtml, setSidebarHtml] = useState("");
  const { currentCommunityMember } = usePunditUserContext();
  const { getValue: getLocalStorageValue, setValue: setLocalStorageValue } =
    useLocalStorage<any>("SpacesContextProvider");
  const onDataLoadSuccess = (data: any) => {
    setRecords(data.records);
    setLocalStorageValue(data.records);
    setSidebarHtml(data.sidebar_html);
    setStatus(LOADED);
  };

  useEffect(() => {
    if (spaces) {
      setRecords(spaces);
      setStatus(LOADED);
    } else {
      if (getIsLocalStorageCacheEnabled()) {
        const localStorageSpaces = getLocalStorageValue();
        if (localStorageSpaces && localStorageSpaces.length > 0) {
          setRecords(localStorageSpaces);
          setStatus(LOADED);
        }
      }

      void loadData();
    }
  }, []);

  const loadData = async () => {
    const response = await spaceApi.index({
      params: { include_sidebar: !!includeSidebar },
    });
    if (response.ok) {
      onDataLoadSuccess(await response.json());
    } else {
      // Add error handling. May be show toast message
    }
  };

  const updateSpace = (newSpace: any) => {
    if (isDataLoaded()) {
      setRecords(
        records.map((space: any) =>
          newSpace.id == space.id ? newSpace : space,
        ),
      );
    } else {
      throw new Error("updateSpace called before data was loaded");
    }
  };

  const refresh = () => {
    setStatus(REFRESHING);
    return loadData();
  };

  const isDataLoaded = () => status === LOADED;
  const isDataLoading = () => status === LOADING;
  const isDataRefreshing = () => status === REFRESHING;

  const findById = (id: any) => findBy({ id });

  // uses: https://lodash.com/docs/4.17.15#find
  const findBy = (conditions = {}) => {
    if (!isDataLoaded()) {
      console.warn(`findBy called when status = ${status}.`);
    }
    return find(records, conditions);
  };

  const spaceWithViewContentAccess = () =>
    records.filter((space: any) =>
      canViewSpaceContent(space, currentCommunityMember),
    );

  const spacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInSpace);

  const spacesWithEventCreationAllowed = () =>
    records.filter(canCreateEventInSpace);

  const basicSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInBasicSpace);

  const basicOrImageSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInBasicOrImageSpace);

  const imageSpacesWithPostCreationAllowed = () =>
    records.filter(canCreatePostInImageSpace);

  const spaceVisibleOnSidebar = () => records;

  const value: any = {
    status,
    records,
    sidebarHtml,
    actions: { loadData, refresh, updateSpace },
    isLoading: isDataLoading() || isDataRefreshing(),
    helpers: {
      isDataLoading,
      isDataRefreshing,
      isDataLoaded,
      findById,
      findBy,
      spacesWithPostCreationAllowed,
      spacesWithEventCreationAllowed,
      basicSpacesWithPostCreationAllowed,
      imageSpacesWithPostCreationAllowed,
      basicOrImageSpacesWithPostCreationAllowed,
      spaceVisibleOnSidebar,
      spaceWithViewContentAccess,
    },
  };
  return (
    <SpacesContext.Provider value={value}>{children}</SpacesContext.Provider>
  );
};

export const useSpacesContext = (): any => useContext(SpacesContext);
