import { appendQueryString } from "./appendQueryString";

type Params = Record<string, any>;

interface UserParams {
  publicUid: string;
}

export const userProfilePaths = {
  posts: (params: UserParams) => `/u/${params.publicUid}`,
  spaces: (params: UserParams) => `/u/${params.publicUid}/spaces`,
  comments: (params: UserParams) => `/u/${params.publicUid}/comments`,
};

interface CommunityMemberParams {
  public_uid: string;
  params?: Params;
}

export const communityMemberProfilePath = ({
  public_uid,
  params = {},
}: CommunityMemberParams) => appendQueryString(`/u/${public_uid}`, params);

export const userProfilePath = (public_uid: string) => `/u/${public_uid}`;

export const userPath = (public_uid: string) => `/u/${public_uid}`;
