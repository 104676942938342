import { absolutePath } from "./absolutePath";

export const paywallGroup = {
  index: ({ params = {} } = {}) => absolutePath("paywalls/groups", params),
  create: ({ params = {} } = {}) => absolutePath("paywalls/groups/", params),
  update: ({ paywallGroupId, params = {} } = {}) =>
    absolutePath(`paywalls/groups/${paywallGroupId}`, params),
  show: ({ paywallGroupId }) =>
    absolutePath(`paywalls/groups/${paywallGroupId}`),
};
