import type { UseQueryOptions } from "react-query";
import { useQuery } from "react-query";
import { spaceApi } from "@circle-react/api";
import type { ApiError } from "@circle-react/config/CustomErrors";
import type { Space } from "@circle-react/types/Space";

export const SPACE_SHOW_REQUEST_KEY = "space-show";

interface UseSpaceResourceOptions {
  spaceId?: number;
  queryOptions?: UseQueryOptions<Space, ApiError>;
}

export const useSpaceResource = ({
  spaceId,
  queryOptions = {},
}: UseSpaceResourceOptions) => {
  const queryKey = [SPACE_SHOW_REQUEST_KEY, spaceId];
  const fetchSpace = () => spaceApi.show({ id: spaceId, forReactQuery: true });

  const query = useQuery<Space, ApiError>(queryKey, fetchSpace, {
    enabled: Boolean(spaceId),
    ...queryOptions,
  });

  return {
    ...query,
    space: query.data,
  };
};
