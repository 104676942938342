import {
  fetchRequest,
  reactQueryGet,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const listTutorialPosts = () =>
  reactQueryGet(internalApi.supportWidget.listTutorialPosts());

const listPosts = ({ spaceId, searchText }) =>
  reactQueryGet(internalApi.supportWidget.listPosts({ spaceId, searchText }));

const listNews = () => reactQueryGet(internalApi.supportWidget.listNews());

const showPost = id =>
  reactQueryGet(internalApi.supportWidget.showPost({ id }));

const showPinnedPost = () =>
  reactQueryGet(internalApi.supportWidget.showPinnedPost());

const spaceGroups = () =>
  reactQueryGet(internalApi.supportWidget.spaceGroups());

const createConversation = ({
  subject,
  text,
  area,
  type,
  is_important,
  zendeskUploadIds,
  source = null,
  chatBotSessionId = null,
  submitPageUrl = "",
  viewportHeight = "",
  viewportWidth = "",
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.supportWidget.createConversation(), {
    method: "POST",
    body: JSON.stringify({
      conversation: {
        subject,
        text,
        area,
        type,
        is_important,
        zendesk_upload_ids: zendeskUploadIds,
        submit_page_url: submitPageUrl,
      },
      source,
      chat_bot_session_id: chatBotSessionId,
      viewport_height: viewportHeight,
      viewport_width: viewportWidth,
    }),
    ...fetchRequestOptions,
  });

const listConversations = () =>
  reactQueryGet(internalApi.supportWidget.listConversations());

const checkConversations = () =>
  reactQueryGet(internalApi.supportWidget.checkConversations());

const showConversation = ({ id }) =>
  reactQueryGet(
    internalApi.supportWidget.showConversation({
      id,
    }),
  );

const updateConversation = ({
  comment,
  id,
  zendeskUploadIds,
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.supportWidget.updateConversation({ id }), {
    method: "PATCH",
    body: JSON.stringify({
      conversation: {
        comment,
        zendesk_upload_ids: zendeskUploadIds,
      },
    }),
    ...fetchRequestOptions,
  });

const createAttachment = ({ signedId, target, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.supportWidget.createAttachment(), {
    method: "POST",
    body: JSON.stringify({
      signed_id: signedId,
      target,
    }),
    ...fetchRequestOptions,
  });

const checkAttachment = ({ zendeskUploadId, target }) =>
  reactQueryGet(
    internalApi.supportWidget.checkAttachment({ zendeskUploadId, target }),
  );

const createPostFeedback = ({ postId, helpful, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.supportWidget.createPostFeedback(), {
    method: "POST",
    body: JSON.stringify({
      post_id: postId,
      helpful,
    }),
    ...fetchRequestOptions,
  });

const listEvents = () => reactQueryGet(internalApi.supportWidget.listEvents());

const createChatMessage = ({
  message,
  authorType,
  sessionId,
  productArea,
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.supportWidget.createChatMessage(), {
    method: "POST",
    body: JSON.stringify({
      message: message,
      author_type: authorType,
      session_id: sessionId,
      product_area: productArea,
    }),
    ...fetchRequestOptions,
  });

const createLiveChatMessage = ({
  message,
  sessionId,
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.supportWidget.createLiveChatMessage(), {
    method: "POST",
    body: JSON.stringify({
      message: message,
      session_id: sessionId,
    }),
    ...fetchRequestOptions,
  });

const completeOnboarding = ({ ...fetchRequestOptions }) =>
  fetchRequest(internalApi.supportWidget.completeOnboarding(), {
    method: "PATCH",
    ...fetchRequestOptions,
  });

const uploadLiveChatAttachment = ({
  mediaUrl,
  mediaIsImage,
  sessionId,
  ...fetchRequestOptions
}) =>
  fetchRequest(internalApi.supportWidget.uploadLiveChatAttachment(), {
    method: "POST",
    body: JSON.stringify({
      media_url: mediaUrl,
      media_is_image: mediaIsImage,
      session_id: sessionId,
    }),
    ...fetchRequestOptions,
  });

const getLiveChatStatus = () =>
  reactQueryGet(internalApi.supportWidget.getLiveChatStatus());

export const supportWidgetApi = {
  listTutorialPosts,
  listPosts,
  listNews,
  showPost,
  showPinnedPost,
  spaceGroups,
  createConversation,
  listConversations,
  checkConversations,
  showConversation,
  updateConversation,
  createAttachment,
  checkAttachment,
  createPostFeedback,
  listEvents,
  createChatMessage,
  createLiveChatMessage,
  uploadLiveChatAttachment,
  completeOnboarding,
  getLiveChatStatus,
};
