import { useEffect } from "react";

export const useDocumentHide = (callback: () => void) => {
  useEffect(() => {
    const handlePageHide = () => {
      void callback();
    };

    window.addEventListener("pagehide", handlePageHide);
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
    };
  }, [callback]);
};
