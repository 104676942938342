import { absolutePath } from "./absolutePath";

export const apiTokens = {
  index: ({ params = {} } = {}) => absolutePath("admin/api_tokens", params),

  create: ({ params = {} } = {}) => absolutePath(`admin/api_tokens`, params),

  destroy: ({ id = "", params = {} } = {}) =>
    absolutePath(`admin/api_tokens/${id}`, params),

  refresh: ({ id = "", params = {} } = {}) =>
    absolutePath(`admin/api_tokens/${id}/refresh`, params),
};
