import { absolutePath } from "./absolutePath";

export const chatRoomMessages = {
  index: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages`, params),
  create: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/`, params),
  update: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  show: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  delete: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}`, params),
  markAsUnread: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/messages/${id}/mark_as_unread`, params),
};
