import { useCallback, useEffect } from "react";
import { useLocalStorageState } from "@circle-react/hooks/utils/useLocalStorageState";
import type { Appearance } from "../themes";
import { APPEARANCE } from "../themes";

export interface UseLocalStorageAppearanceProps {
  defaultAppearance?: Appearance;
}

export interface UseLocalStorageAppearanceReturn {
  currentAppearance?: Appearance | "";
  setAppearance: (value: Appearance) => void;
  toggleAppearance: () => void;
  isDarkAppearance: boolean;
  isLightAppearance: boolean;
}

export const getIsAppearance = (
  appearance: unknown,
): appearance is Appearance =>
  appearance === APPEARANCE.LIGHT || appearance === APPEARANCE.DARK;

export const useLocalStorageAppearance = ({
  defaultAppearance = APPEARANCE.LIGHT,
}: UseLocalStorageAppearanceProps): UseLocalStorageAppearanceReturn => {
  const [currentAppearance, setCurrentAppearance] = useLocalStorageState<
    Appearance | ""
  >("current_appearance", "");

  const setAppearance = useCallback(
    (value: Appearance) => {
      if (value === APPEARANCE.LIGHT || value === APPEARANCE.DARK) {
        setCurrentAppearance(value);
      }
    },
    [setCurrentAppearance],
  );

  useEffect(() => {
    if (window?.webview && !window.webview.setAppearance) {
      window.webview.setAppearance = setAppearance;
    }
  }, [setAppearance]);

  const toggleAppearance = () => {
    if (currentAppearance) {
      if (currentAppearance === APPEARANCE.LIGHT) {
        setCurrentAppearance(APPEARANCE.DARK);
      }
      if (currentAppearance === APPEARANCE.DARK) {
        setCurrentAppearance(APPEARANCE.LIGHT);
      }
    } else {
      if (defaultAppearance === APPEARANCE.LIGHT) {
        setCurrentAppearance(APPEARANCE.DARK);
      }
      if (defaultAppearance === APPEARANCE.DARK) {
        setCurrentAppearance(APPEARANCE.LIGHT);
      }
    }
  };

  const isCommunityDarkOrLightAppearance = (type: Appearance) => {
    if (getIsAppearance(currentAppearance)) {
      return currentAppearance === type;
    }
    return defaultAppearance === type;
  };

  const isDarkAppearance = isCommunityDarkOrLightAppearance(APPEARANCE.DARK);
  const isLightAppearance = isCommunityDarkOrLightAppearance(APPEARANCE.LIGHT);

  return {
    currentAppearance,
    setAppearance,
    toggleAppearance,
    isDarkAppearance,
    isLightAppearance,
  };
};
