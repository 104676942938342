import type { ApiResource } from "@circle-react/components/SettingsApp/SingleSignOn/useAuthProvidersApi";
import { absolutePath } from "./absolutePath";

export const authProviders = {
  index: () => absolutePath("admin/auth_provider"),

  update: ({ params = {} }: ApiResource = {}) =>
    absolutePath("admin/auth_provider", params),

  getStrategies: () => absolutePath("admin/auth_provider/strategies"),
};
