import { absolutePath } from "../../absolutePath";

export const accessGroups = {
  index: ({ params = {} } = {}) => absolutePath("admin/access_groups", params),
  create: () => absolutePath("admin/access_groups"),
  update: (id: number) => absolutePath(`admin/access_groups/${id}`),
  show: (id: number) => absolutePath(`admin/access_groups/${id}`),
  delete: (id: number) => absolutePath(`admin/access_groups/${id}`),
  unarchive: (id: number) =>
    absolutePath(`admin/access_groups/${id}/unarchive`),
};
