import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const STONE_COLORS: PartialTheme = {
  light: {
    "--brand": "#3E7875",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#DDDDDD",
    "--color-community-header-text": "#1E2B31",
    "--color-community-sidebar-background": "#DDDDDD",
    "--color-community-sidebar-text": "#1E2B31",
    "--color-community-sidebar-active-background": "#3E7875",
    "--color-community-active-item-text-color": "#1E2B31",
    "--color-community-header-hover-background": "#C9D0D3",
    "--color-community-sidebar-hover-background": "#C9D0D3",
    "--color-online-background": "#29A4DE",
    "--color-community-sidebar-badge-background": "#B96529",
    "--color-community-header-active-text": "#1E2B31",
    "--color-community-header-active-background": "#C9D0D3",
  },
  dark: {
    "--brand": "#3E7875",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#1E2B31",
    "--color-community-header-text": "#DDDDDD",
    "--color-community-sidebar-background": "#1E2B31",
    "--color-community-sidebar-text": "#DDDDDD",
    "--color-community-sidebar-active-background": "#3E7875",
    "--color-community-active-item-text-color": "#1E2B31",
    "--color-community-header-hover-background": "#38474F",
    "--color-community-sidebar-hover-background": "#38474F",
    "--color-online-background": "#29A4DE",
    "--color-community-sidebar-badge-background": "#B96529",
    "--color-community-header-active-text": "#DDDDDD",
    "--color-community-header-active-background": "#38474F",
  },
};

export const STONE_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...STONE_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...STONE_COLORS.dark,
  },
};
