import { absolutePath } from "../absolutePath";

export const masquerades = {
  create: ({ entityId, entityType, mode }: any) =>
    absolutePath(`masquerading/masquerades`, {
      entity_id: entityId,
      entity_type: entityType,
      mode,
    }),
};
