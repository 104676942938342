import { absolutePath } from "./absolutePath";

export const gamification = {
  settings: ({ params = {} } = {}) =>
    absolutePath("/gamification/settings", params),
  members: ({ params = {} } = {}) =>
    absolutePath("/gamification/members", params),
  leaderboard: ({ params = {} } = {}) =>
    absolutePath("/gamification/leaderboard", params),
  pointAwards: ({ params = {} } = {}) =>
    absolutePath("/gamification/point_awards", params),
  revokePointAward: ({ pointAwardId }) =>
    absolutePath(`/gamification/point_awards/${pointAwardId}/undo`),
};
