import { appendQueryString } from "./appendQueryString";
import { toQueryString } from "./toQueryString";

type Params = Record<string, any>;

interface Args {
  params?: Params;
}

export const settingsPath = {
  paywallsAdminDashboard: {
    charges: ({ params = {} }: Args = {}) =>
      appendQueryString("/settings/paywall_charges", params),
    subscriptions: ({ params = {} }: Args = {}) =>
      appendQueryString("/settings/paywall_subscriptions", params),
  },
  postsDashboard: {
    index: ({ params = {} }: Args = {}) =>
      appendQueryString("/settings/posts", params),
  },
  spacesDashboard: {
    index: ({ params = {} }: Args = {}) =>
      appendQueryString("/settings/spaces", params),
  },
  activityLogsDashboard: {
    index: ({ params = {} }: Args = {}) =>
      appendQueryString("/settings/members/activity_logs", params),
  },
};

export const spacesSettingsPath = () => "/settings/spaces";
export const topicsSettingsPath = () => "/settings/topics";
export const embedSettingsPath = () => "/settings/embed";
export const communityBillingPath = () => "/settings/billing";
export const landingPageSettingsPath = () => "/settings/home";
export const attachmentFilesPath = () => "/settings/communities/attached_files";
export const customizeThemeSettingsPath = () => "/settings/theme";

export const flaggedContent = {
  index: ({ params }: Args = {}) =>
    `/settings/moderation?${toQueryString(params)}`,
};

export const paymentsPath = {
  create: () => "/settings/payments",
  refresh: () => "/settings/payments/refresh",
};
