import { fetchRequest } from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const create = (participant, params = {}) => {
  const { uuid, chat_room_participant } = participant;
  return fetchRequest(internalApi.chatRoomParticipants.create({ uuid }), {
    method: "POST",
    body: JSON.stringify({ chat_room_participant: chat_room_participant }),
    ...params,
  });
};

const update = (participant, params = {}) => {
  const { uuid, id, admin, archived, muted } = participant;
  return fetchRequest(internalApi.chatRoomParticipants.update({ uuid, id }), {
    method: "PATCH",
    body: JSON.stringify({ admin: admin, archived: archived, muted: muted }),
    ...params,
  });
};

const destroy = (participant, params = {}) => {
  const { uuid, id } = participant;
  return fetchRequest(internalApi.chatRoomParticipants.destroy({ uuid, id }), {
    method: "DELETE",
    ...params,
  });
};

export const chatRoomParticipantApi = {
  create,
  update,
  destroy,
};
