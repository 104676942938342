import { isObject } from "lodash";

interface ApiErrorParams {
  status: number;
  statusText: string;
  message: string;
  errorDetails?: string | Record<string, any>;
  body?: unknown;
}

export class ApiError extends Error {
  status: number;
  statusText: string;
  errorDetails?: string | Record<string, any>;
  body?: any;
  firstErrorDetail?: any;

  constructor({
    status,
    statusText,
    message,
    errorDetails,
    body,
  }: ApiErrorParams) {
    super(message);
    this.status = status;
    this.statusText = statusText;
    this.errorDetails = errorDetails;
    this.body = body;

    if (isObject(errorDetails)) {
      this.firstErrorDetail = Object.values(errorDetails)?.[0];
    }
  }
}

interface NotFoundErrorParams {
  message: string;
}

export class NotFoundError extends Error {
  status: number;

  constructor({ message }: NotFoundErrorParams) {
    super(message);
    this.status = 404;
  }
}
