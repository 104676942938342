import { absolutePath } from "./absolutePath";

export const paywallCheckout = {
  show: ({ paywallCheckoutPath }) =>
    absolutePath(`paywalls/checkout/${paywallCheckoutPath}`),
  preview: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/preview", params),
  prepare: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/prepare", params),
  create: ({ params = {} } = {}) => absolutePath("paywalls/checkout/", params),
  complete: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/complete", params),
  cancel: ({ params = {} } = {}) =>
    absolutePath("paywalls/checkout/cancel", params),
};
