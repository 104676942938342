import { absolutePath } from "./absolutePath";

export const communityMembers = {
  show: ({ id, params = {} } = {}) =>
    absolutePath(`community_members/${id}`, params),
  list: ({ params = {} } = {}) => absolutePath(`community_members`, params),
  preference: ({ id, params = {} } = {}) =>
    absolutePath(`community_members/${id}/community_member_preference`, params),
  deactivateAccount: ({ id, params = {} } = {}) =>
    absolutePath(`community_members/${id}/deactivate`, params),
  export: ({ params = {} } = {}) =>
    absolutePath(`community_members/export`, params, {
      arrayFormat: "indices",
    }),
};
