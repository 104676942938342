import PropTypes from "prop-types";
import { QueryClientProvider as ReactQueryProvider } from "react-query";
import { queryClient } from "./reactQueryConfig";

export const QueryClientProvider = ({ children }) => (
  <ReactQueryProvider client={queryClient}>{children}</ReactQueryProvider>
);

QueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export * from "./reactQueryConfig";
