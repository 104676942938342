import { useCallback, useEffect, useMemo, useState } from "react";
import { useFrame } from "react-frame-component";
import type { ThemeObject } from "@circle-react/types/Theme";
import type { ThemeContextProps } from "../ThemeContext";
import { APPEARANCE } from "../themes";
import { updateTheme } from "../updateTheme";

export const useThemeState = (
  {
    default_appearance = APPEARANCE.LIGHT,
    custom_colors = {},
    current_theme = "default",
    is_custom = false,
    brand_color = {
      light: "",
      dark: "",
    },
    brand_text_color = {
      light: "",
      dark: "",
    },
  }: ThemeObject,
  selector = "",
) => {
  const { document } = useFrame();
  const [currentAppearance, setCurrentAppearance] =
    useState(default_appearance);

  useEffect(() => {
    updateTheme({
      theme: {
        current_appearance: currentAppearance,
        custom_colors,
        current_theme,
        is_custom,
        brand_color,
        brand_text_color,
      },
      documentElement: document?.documentElement,
      selector,
    });
  }, [
    brand_color,
    brand_text_color,
    currentAppearance,
    current_theme,
    custom_colors,
    document,
    is_custom,
    selector,
  ]);

  const toggleAppearance = useCallback(() => {
    setCurrentAppearance(
      currentAppearance === APPEARANCE.LIGHT
        ? APPEARANCE.DARK
        : APPEARANCE.LIGHT,
    );
  }, [setCurrentAppearance, currentAppearance]);

  const setAppearance = useCallback(
    (appearance: string) => {
      if (appearance === APPEARANCE.LIGHT || appearance === APPEARANCE.DARK) {
        setCurrentAppearance(appearance);
      }
    },
    [setCurrentAppearance],
  );

  const isDarkAppearance = currentAppearance === APPEARANCE.DARK;
  const isLightAppearance = currentAppearance === APPEARANCE.LIGHT;

  return useMemo<ThemeContextProps>(
    () => ({
      brandColor: brand_color,
      brandTextColor: brand_text_color,
      currentAppearance,
      currentTheme: current_theme,
      customColors: custom_colors,
      isCustomTheme: is_custom,
      isDarkAppearance,
      isLightAppearance,
      setAppearance,
      toggleAppearance,
    }),
    [
      brand_color,
      brand_text_color,
      current_theme,
      currentAppearance,
      custom_colors,
      is_custom,
      isDarkAppearance,
      isLightAppearance,
      setAppearance,
      toggleAppearance,
    ],
  );
};
