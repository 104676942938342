import { absolutePath } from "./absolutePath";

const cancellationMutation = ({ id, params = {} } = {}) =>
  absolutePath(`community_member_subscriptions/${id}/cancellations`, params);

export const communityMemberSubscription = {
  index: ({ params = {} } = {}) =>
    absolutePath("community_member_subscriptions", params),
  cancellation: {
    create: cancellationMutation,
    destroy: cancellationMutation,
  },
};
