import { absolutePath } from "./absolutePath";

export const communityMemberPaymentMethod = {
  index: ({ params = {} } = {}) =>
    absolutePath("community_member_payment_methods", params),
  create: ({ params = {} } = {}) =>
    absolutePath("community_member_payment_methods", params),
  setupIntent: {
    create: ({ params = {} } = {}) =>
      absolutePath("community_member_payment_methods/setup_intents", params),
  },
};
