import { absolutePath } from "../../absolutePath";

const buildRoute = (communityMemberId: number) =>
  absolutePath(`paywalls/community_members/${communityMemberId}/billing_info`);

export const billingInfo = {
  create: (communityMemberId: number) => buildRoute(communityMemberId),
  show: (communityMemberId: number) => buildRoute(communityMemberId),
  update: (communityMemberId: number) => buildRoute(communityMemberId),
};
