import { toQueryString } from "./toQueryString";

const BASE_PATH = "/api";

type Params = Record<string, any>;

export const absolutePath = (path: string, params: Params = {}) =>
  `${BASE_PATH}/${path}?${toQueryString(params)}`;

interface Args {
  slug: string;
  params?: Params;
}

export const publicLiveStreams = {
  rooms: {
    show: ({ slug, params = {} }: Args) =>
      absolutePath(`public/live_streams/rooms/${slug}`, params),
    participants: {
      index: ({ slug, params = {} }: Args) =>
        absolutePath(`public/live_streams/rooms/${slug}/participants`, params),
    },
    messages: {
      index: ({ slug, params = {} }: Args) =>
        absolutePath(`public/live_streams/rooms/${slug}/messages`, params),
    },
    guests: {
      index: ({ slug, params = {} }: Args) =>
        absolutePath(`public/live_streams/rooms/${slug}/guests`, params),
    },
  },
};
