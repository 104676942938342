export type Params = Record<string, unknown> | unknown[];

type Keys = string[];
type Value = string;
type Pair = [Keys, Value];
type Pairs = Pair[];

export interface Options {
  arrayFormat?: "brackets" | "indices";
}

const isRecord = (value: unknown): value is Record<string, unknown> =>
  typeof value === "object" && value !== null && !Array.isArray(value);

const isArray = (value: unknown): value is unknown[] => Array.isArray(value);

const getPairs = (obj: Params = {}, keys: Keys = [], options: Options = {}) =>
  Object.entries(obj).reduce<Pairs>((pairs, [key, value]) => {
    if (isArray(value)) {
      value.forEach((currentValue, index) => {
        const arrayIndex =
          options.arrayFormat === "indices" ? String(index) : "";
        if (isRecord(currentValue) || isArray(currentValue)) {
          pairs.push(...getPairs(currentValue, [...keys, key, arrayIndex]));
          return;
        }
        pairs.push([
          [...keys, key, arrayIndex],
          encodeURIComponent(String(currentValue)),
        ]);
      });
    } else if (isRecord(value)) {
      pairs.push(...getPairs(value, [...keys, key]));
    } else {
      pairs.push([[...keys, key], encodeURIComponent(String(value))]);
    }
    return pairs;
  }, []);

export const toQueryString = (params: Params = {}, options: Options = {}) =>
  getPairs(params, [], options)
    .map(([[firstKey, ...remainingKeys], value]) => {
      const remaining = remainingKeys.map(key => `[${key}]`).join("");
      return `${firstKey}${remaining}=${value}`;
    })
    .join("&");

const getQueryObject = () => new URLSearchParams(window.location.search);

export const appendQueryParam = (key: string, value: string) => {
  const queryObject = getQueryObject();
  queryObject.append(key, value);
  return queryObject.toString();
};

export const updateQueryParam = (key: string, value: string) => {
  const queryObject = getQueryObject();
  queryObject.set(key, value);
  return queryObject.toString();
};

export const removeQueryParam = (key: string) => {
  const queryObject = getQueryObject();
  queryObject.delete(key);
  return queryObject.toString();
};

export const appendOrRemoveQueryParam = (
  key: string = "",
  value: string = "",
) => {
  if (typeof value === "string" && value.length > 0) {
    return appendQueryParam(key, value);
  }
  return removeQueryParam(key);
};

export const updateOrRemoveQueryParam = (
  key: string = "",
  value: string = "",
) => {
  if (typeof value === "string" && value.length > 0) {
    return updateQueryParam(key, value);
  }
  return removeQueryParam(key);
};

export const getQueryParam = (key: string) => getQueryObject().get(key);

export const serializeObjectToQueryParam = (obj: Record<string, string>) =>
  new URLSearchParams(obj).toString();
