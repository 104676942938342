import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const SAND_DOLLAR_COLORS: PartialTheme = {
  light: {
    "--brand": "#61315F",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FEF3ED",
    "--color-community-header-text": "#3E3747",
    "--color-community-sidebar-background": "#FEF3ED",
    "--color-community-sidebar-text": "#3E3747",
    "--color-community-sidebar-active-background": "#61315F",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#FFEADE",
    "--color-community-sidebar-hover-background": "#FFEADE",
    "--color-online-background": "#17A7BB",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#3E3747",
    "--color-community-header-active-background": "#FFEADE",
  },
  dark: {
    "--brand": "#A44FA1",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FEF3ED",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FEF3ED",
    "--color-community-sidebar-active-background": "#A44FA1",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#694835",
    "--color-community-sidebar-hover-background": "#694835",
    "--color-online-background": "#17A7BB",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#FEF3ED",
    "--color-community-header-active-background": "#694835",
  },
};

export const SAND_DOLLAR_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...SAND_DOLLAR_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...SAND_DOLLAR_COLORS.dark,
  },
};
