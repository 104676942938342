import { absolutePath } from "./absolutePath";

type Params = Record<string, any>;

interface SpaceIdParams {
  params?: Params;
  spaceId?: string | number;
}

interface PostsShowParams {
  params?: Params;
  postSlug?: string;
  spaceId?: number;
  spaceSlug?: string;
}

interface SpaceIdPostSlugParams {
  params?: Params;
  postSlug?: string;
  spaceId?: number;
}

interface PostIdParams {
  params?: Params;
  postId?: string;
}

const emptyArgs = {
  params: {},
  postSlug: "",
  spaceSlug: "",
};

const featuredAreasVisibilityMutation = ({
  spaceId,
  postSlug,
  params = {},
}: SpaceIdPostSlugParams = emptyArgs) =>
  absolutePath(
    `spaces/${spaceId}/posts/${postSlug}/featured_areas_visibility`,
    params,
  );

export const posts = {
  index: ({ spaceId, params }: SpaceIdParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts`, params),
  create: ({ spaceId, params }: SpaceIdParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts`, params),
  update: ({
    spaceId,
    postSlug,
    params = {},
  }: SpaceIdPostSlugParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts/${postSlug}`, params),
  show: ({
    spaceId,
    spaceSlug,
    postSlug,
    params = {},
  }: PostsShowParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId || spaceSlug}/posts/${postSlug}`, params),
  destroy: ({
    spaceId,
    postSlug,
    params = {},
  }: SpaceIdPostSlugParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts/${postSlug}`, params),
  pin: ({
    spaceId,
    postSlug,
    params = {},
  }: SpaceIdPostSlugParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts/${postSlug}/pin`, params),
  duplicate: ({
    spaceId,
    postSlug,
    params = {},
  }: SpaceIdPostSlugParams = emptyArgs) =>
    absolutePath(`spaces/${spaceId}/posts/${postSlug}/duplicate`, params),
  featuredAreasVisibility: {
    create: featuredAreasVisibilityMutation,
    destroy: featuredAreasVisibilityMutation,
  },
  updateKnowledgeBasePost: ({
    postId,
    params = {},
  }: PostIdParams = emptyArgs) =>
    absolutePath(`posts/${postId}/update_knowledge_base_post`, params),
  userLikesList: ({ postId, params = {} }: PostIdParams = emptyArgs) =>
    absolutePath(`posts/${postId}/user_likes`, params),
};
