import { useContext } from "react";
import { createContext } from "react";

export const USAGE_SECTIONS = {
  USER: "user",
  ADMIN: "admin",
} as const;

export type UsageSections = "user" | "admin";

export interface UsageContextType {
  section: UsageSections;
  isAdminUsageContext: boolean;
}

/**
 * For adding customised UI between usage contexts.
 * An example would be the buttons - circle branded in admin usage contexts and primary in user contexts.
 */
export const UsageContext = createContext<UsageContextType>({
  section: USAGE_SECTIONS.USER,
  isAdminUsageContext: false,
});

UsageContext.displayName = "UsageContext";

export const useUsageContext = () => useContext(UsageContext);
