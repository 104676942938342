import {
  fetchRequest,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const index = () =>
  fetchRequest(internalApi.communityPlanAddons.index(), {
    method: "GET",
  });

const create = params =>
  reactQueryPost(internalApi.communityPlanAddons.create(), {
    community_plan_addons: params,
  });

export const communityPlanAddonApi = {
  index,
  create,
};
