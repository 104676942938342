import { absolutePath } from "../absolutePath";

export const search = {
  communityMembers: {
    index: ({ params = {} }) =>
      absolutePath("admin/search/community_members", params),
    /* Enable multiple instances and operators */
    indexV2: ({ params = {} }) =>
      absolutePath("v2/admin/search/community_members", params, {
        arrayFormat: "indices",
      }),
  },
  contacts: {
    index: ({ params = {} }) =>
      absolutePath("v2/admin/search/contacts", params, {
        arrayFormat: "indices",
      }),
  },
};
