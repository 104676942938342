import { absolutePath } from "./absolutePath";

export const eventAttendees = {
  index: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/event_attendees`, params),
  exportCsv: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/export_csv`,
      params,
    ),
  searchCommunityMembers: ({ eventId, params = {} } = {}) =>
    absolutePath(`search/events/${eventId}/community_members`, params),
  bulkRsvp: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/bulk_rsvp`,
      params,
    ),
  destroyById: ({ slug, spaceId, attendeeId, params = {} } = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/event_attendees/${attendeeId}/destroy_by_id`,
      params,
    ),
};
