import { absolutePath } from "./absolutePath";

export const communityMemberCharge = {
  show: ({ processorId }) =>
    absolutePath(`community_member_charges/${processorId}`),
  index: ({ params = {} } = {}) =>
    absolutePath("community_member_charges", params),
  invoice: {
    show: ({ chargeId }) =>
      absolutePath(`community_member_charges/invoices/${chargeId}`),
  },
};
