interface ActiveStorageBlobUrlParams {
  signed_id: string;
  filename: string;
}

export const activeStorageBlobUrl = ({
  signed_id,
  filename,
}: ActiveStorageBlobUrlParams) =>
  `${
    window.location.origin
  }/rails/active_storage/blobs/${signed_id}/${encodeURIComponent(filename)}`;
