import { toQueryString } from "../toQueryString";

const path = (path: any, params: any = {}) =>
  `${path}?${toQueryString(params)}`;

export const broadcasts = {
  list: ({ params = {} }: any = {}) => path("/internal_api/broadcasts", params),
  create: ({ params = {} }: any = {}) =>
    path("/internal_api/broadcasts", params),
  show: ({ id, params = {} }: { id: number; params?: object }) =>
    path(`/internal_api/broadcasts/${id}`, params),
  sendNow: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/send_now`),
  duplicate: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/duplicate`),
  recipientList: ({ id, params = {} }: { id: number; params?: unknown }) =>
    path(`/internal_api/broadcasts/${id}/recipients`, params),
  report: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/report`),
  recipients: ({ id, params = {} }: { id: number; params?: object }) =>
    path(`/internal_api/broadcasts/${id}/recipients`, params),
  cancelSchedule: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/cancel_schedule`),
  rename: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/rename`),
  sendTestEmail: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}/send_test_email`),
  deleteBroadcast: ({ id }: { id: number }) =>
    path(`/internal_api/broadcasts/${id}`),
};
