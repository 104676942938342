import { toQueryString } from "./toQueryString";

type Params = Record<string, any>;

interface IndexParams {
  spaceSlug?: string;
  params?: Params;
}

interface NewParams {
  spaceSlug: string;
}

interface DuplicateParams {
  spaceSlug?: string;
  slug?: string;
  params?: Params;
}

interface PinParams {
  spaceSlug?: string;
  slug?: string;
  params?: Params;
}

interface PostsPathParam {
  spaceSlug?: string;
  slug?: string;
  params?: Params;
  hash?: string;
}

export const postsPath = ({
  spaceSlug,
  slug,
  params = {},
  hash = "",
}: PostsPathParam = {}) =>
  Object.keys(params).length
    ? `/c/${spaceSlug}/${slug}?${toQueryString(params)}${hash}`
    : `/c/${spaceSlug}/${slug}${hash}`;

export const posts = {
  index: ({ spaceSlug, params }: IndexParams = {}) =>
    `/c/${spaceSlug}${
      params && Object.keys(params).length ? "?" : ""
    }${toQueryString(params)}`,
  new: ({ spaceSlug }: NewParams) => `/c/${spaceSlug}/new`,
  show: postsPath,
  destroy: postsPath,
  newDuplicate: ({ spaceSlug, slug, params }: DuplicateParams = {}) =>
    `/c/${spaceSlug}/${slug}/new_duplicate?${toQueryString(params)}`,
  pinToTop: ({ spaceSlug, slug, params }: PinParams = {}) =>
    `/c/${spaceSlug}/${slug}/pin?${toQueryString(params)}`,
};
