import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const HACKER_COLORS: PartialTheme = {
  light: {
    "--brand": "#36DD00",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#EBEBEB",
    "--color-community-header-text": "#000000",
    "--color-community-sidebar-background": "#EBEBEB",
    "--color-community-sidebar-text": "#000000",
    "--color-community-sidebar-active-background": "#36DD00",
    "--color-community-active-item-text-color": "#000000",
    "--color-community-header-hover-background": "#D8D8D8",
    "--color-community-sidebar-hover-background": "#D8D8D8",
    "--color-online-background": "#36DD00",
    "--color-community-sidebar-badge-background": "#152C0E",
    "--color-community-header-active-text": "#000000",
    "--color-community-header-active-background": "#D8D8D8",
  },
  dark: {
    "--brand": "#74F64A",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#101010",
    "--color-community-header-text": "#73F44A",
    "--color-community-sidebar-background": "#101010",
    "--color-community-sidebar-text": "#73F44A",
    "--color-community-sidebar-active-background": "#73F44A",
    "--color-community-active-item-text-color": "#000000",
    "--color-community-header-hover-background": "#1E3416",
    "--color-community-sidebar-hover-background": "#1E3416",
    "--color-online-background": "#74F64A",
    "--color-community-sidebar-badge-background": "#152C0E",
    "--color-community-header-active-text": "#73F44A",
    "--color-community-header-active-background": "#1E3416",
  },
};

export const HACKER_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...HACKER_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...HACKER_COLORS.dark,
  },
};
