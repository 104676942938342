import {
  fetchRequest,
  reactQueryGet,
  reactQueryPost,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const index = ({ params = {}, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.spaces.index({ params }), {
    ...fetchRequestOptions,
    method: "GET",
  });

const show = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.spaces.show({ id }), {
    ...fetchRequestOptions,
    method: "GET",
  });

const list = ({ params = {} }) =>
  reactQueryGet(internalApi.spaces.index({ params }));

const addMemberToSpace = ({ spaceId, body }) =>
  reactQueryPost(internalApi.spaces.addMemberToSpace({ spaceId }), body);

const destroy = ({ id, ...fetchRequestOptions }) =>
  fetchRequest(internalApi.spaces.destroy({ id }), {
    ...fetchRequestOptions,
    method: "DELETE",
  });

export const spaceApi = {
  index,
  list,
  show,
  addMemberToSpace,
  destroy,
};
