import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const MILKSHAKE_COLORS: PartialTheme = {
  light: {
    "--brand": "#5D83BF",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FADCE3",
    "--color-community-header-text": "#550357",
    "--color-community-sidebar-background": "#FADCE3",
    "--color-community-sidebar-text": "#550357",
    "--color-community-sidebar-active-background": "#5D83BF",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "",
    "--color-community-sidebar-hover-background": "#F7CDD4",
    "--color-online-background": "#FB424E",
    "--color-community-sidebar-badge-background": "#5D83BF",
    "--color-community-header-active-text": "#550357",
    "--color-community-header-active-background": "#F7CDD4",
  },
  dark: {
    "--brand": "#5D83BF",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FADCE3",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FADCE3",
    "--color-community-sidebar-active-background": "#5D83BF",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#E8C4CD",
    "--color-community-sidebar-hover-background": "#E8C4CD",
    "--color-online-background": "#FB424E",
    "--color-community-sidebar-badge-background": "#5D83BF",
    "--color-community-header-active-text": "#FADCE3",
    "--color-community-header-active-background": "#E8C4CD",
  },
};

export const MILKSHAKE_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...MILKSHAKE_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...MILKSHAKE_COLORS.dark,
  },
};
