import { absolutePath } from "./absolutePath";

export const communityMemberSubscriptionPriceChanges = {
  preview: ({ subscriptionId, params = {} } = {}) =>
    absolutePath(
      `community_member_subscriptions/${subscriptionId}/price_changes/preview`,
      params,
    ),
  create: ({ subscriptionId, params = {} } = {}) =>
    absolutePath(
      `community_member_subscriptions/${subscriptionId}/price_changes`,
      params,
    ),
};
