import { createContext, useContext } from "react";
import type { ReactNode } from "react";
import type { AppearanceColor, ThemeObject } from "@circle-react/types/Theme";

// Used while we transition to full react app.
// Right only part of the app is rendered under App.jsx
// So this context server 2 purpose
// 1. Share the backend props with the whole app
// 2. within component which are shared with App.jsx subtree and Rails rendered react, we can identify from where the component was rendered/

// BackendProps come from app/views/shared/_react_app.html.erb
export interface BackendProps {
  alert: string;
  brand_color: AppearanceColor;
  brand_text_color: AppearanceColor;
  community_theme_settings: ThemeObject;
  current_community_member_id: string;
  display_community_switcher: boolean;
  display_header: boolean;
  masquerade_referrer_url: string;
  notice: string;
  minimal_layout: boolean;
  show_widget_bot: boolean;
}

type EmptyObject = Record<string, never>;

interface AppContextProps {
  backendProps: BackendProps | EmptyObject;
}

const AppContext = createContext<AppContextProps | EmptyObject>({});
AppContext.displayName = "AppContext";

const useAppContext = () => useContext(AppContext);

interface AppContextProviderProps {
  backendProps?: BackendProps | EmptyObject;
  children: ReactNode;
}

export const AppContextProvider = ({
  backendProps = {},
  children,
}: AppContextProviderProps) => {
  const value = { backendProps };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useBackendProps = () => useAppContext().backendProps;
