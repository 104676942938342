import { useEffect, useMemo } from "react";
import { isEmpty } from "lodash";
import { getIsLocalStorageCacheEnabled } from "@/react/helpers/communityHelpers";
import { useLocalStorage } from "@/react/hooks/utils/useLocalStorage";

export interface UseReactQueryLocalStorageProps {
  key: string;
  data: any;
  defaultValue?: any;
}

export const useReactQueryLocalStorage = ({
  key,
  data,
  defaultValue = null,
}: UseReactQueryLocalStorageProps) => {
  const { getValue, setValue } = useLocalStorage(key);
  const fromLocalStorage = useMemo(() => getValue(), [getValue]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setValue(data);
    }
  }, [setValue, data]);

  if (!getIsLocalStorageCacheEnabled()) {
    return {
      data: data || defaultValue,
      isLoading: true,
    };
  }
  const responseData = data || fromLocalStorage || defaultValue;

  return {
    data: responseData,
    isLoading: isEmpty(responseData),
  };
};
