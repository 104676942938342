import { absolutePath } from "./absolutePath";

export const polls = {
  create: () => absolutePath("polls"),
  update: id => absolutePath(`polls/${id}`),
  showBySgid: sgid => absolutePath(`polls/sgid/${sgid}`),
  respond: pollId => absolutePath(`polls/${pollId}/poll_responses`),
  undo: pollId => absolutePath(`polls/${pollId}/poll_responses/undo`),
  voteList: ({ pollId, pollOptionId, params = {} }) =>
    absolutePath(
      `polls/${pollId}/poll_responses?poll_option_id=${pollOptionId}`,
      params,
    ),
};
