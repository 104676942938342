import {
  fetchRequest,
  reactQueryDelete,
  reactQueryPost,
  reactQueryPut,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const index = () =>
  fetchRequest(internalApi.spaceGroups.index(), {
    method: "GET",
  });

const addMembers = ({ spaceGroupId, body }) =>
  reactQueryPost(
    internalApi.spaceGroups.addMemberToSpaceGroup({ spaceGroupId }),
    body,
  );

const create = payload =>
  reactQueryPost(internalApi.spaceGroups.create(), payload);

const update = (id, payload) =>
  reactQueryPut(internalApi.spaceGroups.update({ id }), payload);

const destroy = (id, payload) =>
  reactQueryDelete(internalApi.spaceGroups.destroy({ id }), payload);

export const spaceGroupApi = {
  index,
  addMembers,
  create,
  update,
  destroy,
};
