import { absolutePath } from "./absolutePath";

export const richTextFields = {
  mentionsList: ({ params = {} }) =>
    absolutePath("rich_text_fields/mentions", params),
  unfurlURLs: ({ params = {} }) =>
    absolutePath("rich_text_fields/unfurl_urls", params),
  fetchAttachment: ({ signedId, params = {} }) =>
    absolutePath(`rich_text_fields/attachments/${signedId}`, params),
  createOembed: ({ params = {} }) =>
    absolutePath("rich_text_fields/oembeds", params),
  fetchOembed: ({ sgid, params = {} }) =>
    absolutePath(`rich_text_fields/oembeds/${sgid}`, params),
  fetchSgidRecords: ({ params = {} }) =>
    absolutePath("rich_text_fields/sgids", params),
};
