import type { AudienceType } from "@circle-react/types/AudienceList";

const memberIdString = ":memberPublicId";

interface PathWithState {
  pathname: string;
  state?: {
    from: unknown;
  };
}

export const buildAudienceMemberEditPath = (
  path: string,
  prevLocation: unknown,
): PathWithState => {
  const prevLocationObject = prevLocation
    ? { state: { from: prevLocation } }
    : {};

  return {
    pathname: path,
    ...prevLocationObject,
  };
};

export const getAdminAudienceEditPaths = ({
  publicId = memberIdString,
  type,
}: {
  publicId?: string;
  type: AudienceType;
}) => {
  const urlType = type === "CommunityMember" ? "members" : "contacts";
  const adminAudienceBasePath = `/admin/${urlType}/${publicId}`;

  return {
    index: adminAudienceBasePath,
    info: `${adminAudienceBasePath}/info`,
    access: `${adminAudienceBasePath}/access`,
    billing: `${adminAudienceBasePath}/billing`,
    activityLogs: `${adminAudienceBasePath}/activity_logs`,
  };
};

export const getAdminAudienceEditPathsWithState = ({
  publicId = memberIdString,
  type,
  previousLocation,
}: {
  publicId?: string;
  type: AudienceType;
  previousLocation?: unknown;
}) => {
  const paths = getAdminAudienceEditPaths({ publicId, type });

  return {
    index: buildAudienceMemberEditPath(paths.index, previousLocation),
    info: buildAudienceMemberEditPath(paths.info, previousLocation),
    access: buildAudienceMemberEditPath(paths.access, previousLocation),
    billing: buildAudienceMemberEditPath(paths.billing, previousLocation),
    activityLogs: buildAudienceMemberEditPath(
      paths.activityLogs,
      previousLocation,
    ),
  };
};
