import { absolutePath } from "../absolutePath";

export const communityMemberPaymentMethods = {
  index: ({ params = {} } = {}) =>
    absolutePath("admin/community_member_payment_methods", params),
  createPaymentMethod: () =>
    absolutePath("admin/community_member_payment_methods"),
  createSetupIntent: () =>
    absolutePath("admin/community_member_payment_methods/setup_intents"),
};
