import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const FLAMINGO_COLORS: PartialTheme = {
  light: {
    "--brand": "#3FBEBE",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FFFFFF",
    "--color-community-header-text": "#222222",
    "--color-community-sidebar-background": "#FFFFFF",
    "--color-community-sidebar-text": "#222222",
    "--color-community-sidebar-active-background": "#3FBEBE",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#EEEEEE",
    "--color-community-sidebar-hover-background": "#EEEEEE",
    "--color-online-background": "#EE5A67",
    "--color-community-sidebar-badge-background": "#70CBCA",
    "--color-community-header-active-text": "#222222",
    "--color-community-header-active-background": "#EEEEEE",
  },
  dark: {
    "--brand": "#3FBEBE",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FFFFFF",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FFFFFF",
    "--color-community-sidebar-active-background": "#3FBEBE",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#494949",
    "--color-community-sidebar-hover-background": "#494949",
    "--color-online-background": "#EE5A67",
    "--color-community-sidebar-badge-background": "#70CBCA",
    "--color-community-header-active-text": "#FFFFFF",
    "--color-community-header-active-background": "#494949",
  },
};

export const FLAMINGO_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...FLAMINGO_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...FLAMINGO_COLORS.dark,
  },
};
