import { BEACH_CITY_THEME } from "./BeachCity";
import { DEFAULT_THEME } from "./Default";
import { ESPRESSO_THEME } from "./Espresso";
import { FLAMINGO_THEME } from "./Flamingo";
import { HACKER_THEME } from "./Hacker";
import { HIGH_CONTRAST_THEME } from "./HighContrast";
import { MILKSHAKE_THEME } from "./Milkshake";
import { MOODY_THEME } from "./Moody";
import { NEON_NIGHT_THEME } from "./NeonNight";
import { NIGHTSHADE_THEME } from "./Nightshade";
import { POP_ROCKS_THEME } from "./PopRocks";
import { SAGE_THEME } from "./Sage";
import { SAND_DOLLAR_THEME } from "./SandDollar";
import { SLOW_BURN_THEME } from "./SlowBurn";
import { STONE_THEME } from "./Stone";
import { SWEET_TOOTH_THEME } from "./SweetTooth";
import { UNDERGROUND_THEME } from "./Underground";
import { VERY_PERI_THEME } from "./VeryPeri";
import { ZEN_MIND_THEME } from "./ZenMind";
import type { ThemeOptions } from "./types";

export const THEME_MAP: any = {
  default: DEFAULT_THEME,
  beachCity: BEACH_CITY_THEME,
  espresso: ESPRESSO_THEME,
  flamingo: FLAMINGO_THEME,
  hacker: HACKER_THEME,
  highContrast: HIGH_CONTRAST_THEME,
  milkshake: MILKSHAKE_THEME,
  moody: MOODY_THEME,
  neonNight: NEON_NIGHT_THEME,
  nightshade: NIGHTSHADE_THEME,
  popRocks: POP_ROCKS_THEME,
  sage: SAGE_THEME,
  sandDollar: SAND_DOLLAR_THEME,
  slowBurn: SLOW_BURN_THEME,
  stone: STONE_THEME,
  sweetTooth: SWEET_TOOTH_THEME,
  underground: UNDERGROUND_THEME,
  veryPeri: VERY_PERI_THEME,
  zenMind: ZEN_MIND_THEME,
};

const isThemeOptions = (key: string): key is ThemeOptions =>
  Object.keys(THEME_MAP).includes(key);

export const THEME_OPTIONS: ThemeOptions[] =
  Object.keys(THEME_MAP).filter(isThemeOptions);

export * from "./constants";
export * from "./types";
