export const v2Colors = {
  gray: {
    50: "247, 249, 250", // "#F7F9FA"
    100: "240, 243, 245", // "#F0F3F5"
    200: "228, 231, 235", // "#E4E7EB"
    300: "210, 215, 219", // "#D2D7DB"
    400: "165, 169, 173", // "#A5A9AD"
    500: "113, 118, 128", // "#717680"
    600: "84, 88, 97", // "#545861"
    700: "66, 69, 77", // "#42454D"
    800: "43, 46, 51", // "#2B2E33"
    900: "25, 27, 31", // "#191B1F"
  },
};

export const applyV2Colors = () => {
  const root = document.documentElement;
  root.style.setProperty("--color-gray-50", v2Colors.gray["50"]);
  root.style.setProperty("--color-gray-100", v2Colors.gray["100"]);
  root.style.setProperty("--color-gray-200", v2Colors.gray["200"]);
  root.style.setProperty("--color-gray-300", v2Colors.gray["300"]);
  root.style.setProperty("--color-gray-400", v2Colors.gray["400"]);
  root.style.setProperty("--color-gray-500", v2Colors.gray["500"]);
  root.style.setProperty("--color-gray-600", v2Colors.gray["600"]);
  root.style.setProperty("--color-gray-700", v2Colors.gray["700"]);
  root.style.setProperty("--color-gray-800", v2Colors.gray["800"]);
  root.style.setProperty("--color-gray-900", v2Colors.gray["900"]);
  root.style.setProperty("--text-secondary", `rgb(${v2Colors.gray["600"]})`);
  root.style.setProperty("--text-body", `rgb(${v2Colors.gray["600"]})`);
  root.style.setProperty("--text-body-100", `rgb(${v2Colors.gray["800"]})`);
};

export const applyV3Fonts = () => {
  const root = document.documentElement;
  root.style.setProperty(
    "--font-system-ui",
    `InterVariable, system-ui, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol", "Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
  );
};
