import { t } from "i18n-js";
import { MutationCache, QueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";

// https://react-query.tanstack.com/guides/default-query-function
const defaultQueryFn = ({ queryKey }) => reactQueryGet(queryKey[0]);

export const FIVE_MIN_STALE_TIME = 5 * 60 * 1000;

export const defaultQueryConfig = {
  refetchOnWindowFocus: false,
  notifyOnChangeProps: [
    "data",
    "error",
    "isLoading",
    "refetch",
    "isFetchingMore",
  ],
};

export const reactQueryConfig = {
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      ...defaultQueryConfig,
    },
  },
  mutationCache: new MutationCache({
    onError: data => {
      if (data?.body?.view_only_masquerading) {
        if (typeof window.onViewOnlyModeError === "function") {
          window.onViewOnlyModeError(t("view_only_error"));
        }
        throw new Error(t("view_only_error"));
      }
    },
  }),
};

export const queryClient = new QueryClient(reactQueryConfig);
