import { absolutePath } from "./absolutePath";

export const supportWidget = {
  listTutorialPosts: () => absolutePath("support_widget/home"),
  listPosts: ({ spaceId, searchText }) =>
    absolutePath("support_widget/posts", {
      space_id: spaceId || "",
      search_text: searchText || "",
    }),
  listNews: () => absolutePath("support_widget/news"),
  showPost: ({ id }) => absolutePath(`support_widget/posts/${id}`),
  showPinnedPost: () => absolutePath(`support_widget/home/pinned_post`),
  spaceGroups: () => absolutePath("support_widget/space_groups"),
  createConversation: () => absolutePath("support_widget/conversations"),
  listConversations: () => absolutePath("support_widget/conversations"),
  checkConversations: () => absolutePath("support_widget/conversations/check"),
  showConversation: ({ id }) =>
    absolutePath(`support_widget/conversations/${id}`),
  updateConversation: ({ id }) =>
    absolutePath(`support_widget/conversations/${id}`),
  createAttachment: () => absolutePath("support_widget/attachments"),
  checkAttachment: ({ zendeskUploadId, target }) =>
    absolutePath(`support_widget/attachments/${zendeskUploadId}/check`, {
      target: target || "",
    }),
  createPostFeedback: () => absolutePath("support_widget/post_feedbacks"),
  listEvents: () => absolutePath("support_widget/events"),
  createChatMessage: () => absolutePath("support_widget/chats"),
  createLiveChatMessage: () => absolutePath("support_widget/live_chats"),
  downloadChat: id => absolutePath(`support_widget/chats/${id}/download`),
  completeOnboarding: () =>
    absolutePath("support_widget/onboardings/mark_as_completed"),
  uploadLiveChatAttachment: () =>
    absolutePath("support_widget/live_chats/upload"),
  getLiveChatStatus: ({ flow } = {}) =>
    absolutePath("support_widget/live_chats/status", { flow }),
};
