import type { ReactNode } from "react";
import { useMemo } from "react";
import { useThemeObject } from "@circle-react/providers";
import type { ThemeObject } from "@circle-react/types/Theme";
import { ThemeProvider } from "../ThemeProvider";

export interface CircleBrandThemeProviderProps {
  children: ReactNode;
  selector?: string;
}

export const CircleBrandThemeProvider = ({
  children,
  selector = "",
}: CircleBrandThemeProviderProps) => {
  const { default_appearance } = useThemeObject();

  const circleTheme = useMemo<ThemeObject>(
    () => ({
      default_appearance,
      is_custom: false,
      current_theme: "default",
      brand_color: {
        light: "#506CF0",
        dark: "#506CF0",
      },
      brand_text_color: {
        light: "#ffffff",
        dark: "#ffffff",
      },
    }),
    [default_appearance],
  );

  return (
    <ThemeProvider theme={circleTheme} selector={selector}>
      {children}
    </ThemeProvider>
  );
};
