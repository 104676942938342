import { absolutePath } from "../../absolutePath";

export const affiliate = {
  show: (communityMemberId: number) =>
    absolutePath(`paywalls/community_members/${communityMemberId}/affiliate`),
  enroll: (communityMemberId: number) =>
    absolutePath(
      `paywalls/community_members/${communityMemberId}/affiliate/enroll`,
    ),
  commissions: {
    index: (
      communityMemberId: number,
      params: { [key: string]: string | number | object },
    ) =>
      absolutePath(
        `paywalls/community_members/${communityMemberId}/affiliate/commissions`,
        params,
      ),
  },
};
