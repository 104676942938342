import { absolutePath } from "./absolutePath";

export const accountAuthentication = {
  connectedAccount: {
    index: () => absolutePath(`account/authentication/connected_accounts`, {}),
    delete: ({ id }: { id: string }) =>
      absolutePath(`account/authentication/connected_accounts/${id}`, {}),
  },
  users: {
    update: () => absolutePath(`account/authentication/users`, {}),
  },
};
