import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const DIRTY_FORM_INITIAL_STATES = {
  customize: {
    general: false,
    access: false,
    customizeLayout: false,
    lockScreen: false,
    images: false,
  },
  options: {
    options: false,
    seo: false,
  },
};

const ChatRoomsContext = createContext(DIRTY_FORM_INITIAL_STATES);
ChatRoomsContext.displayName = "ChatRoomsContext";

export const CustomizeSpaceContextProvider = ({ onSlugChange, children }) => {
  const [dirtyForms, setDirtyForms] = useState(DIRTY_FORM_INITIAL_STATES);

  const value = {
    dirtyForms,
    setDirtyForms,
    onSlugChange,
  };

  return (
    <ChatRoomsContext.Provider value={value}>
      {children}
    </ChatRoomsContext.Provider>
  );
};

export const useCustomizeSpaceContext = () => useContext(ChatRoomsContext);

CustomizeSpaceContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
