import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useQueries, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";
import { useCurrentCommunityMember } from "@circle-react/contexts/punditUserContext";

const PostsDetailsContext = createContext({});
PostsDetailsContext.displayName = "PostsDetailsContext";

export const DEFAULT_POST_DETAILS_QUERY_CONTEXT = "default";
export const POST_DETAILS_QUERY_KEY = "post_details";

export const findMatchingQueryKeysForPostId = ({
  postId,
  queryClient,
  queryContext = DEFAULT_POST_DETAILS_QUERY_CONTEXT,
}) => {
  const queryCache = queryClient.getQueryCache();
  const activeQueryKeys = queryCache.getAll().map(cache => cache.queryKey);
  return activeQueryKeys.filter(
    queryKey =>
      queryKey &&
      queryKey.length === 3 &&
      queryKey[0] === queryContext &&
      queryKey[1] === POST_DETAILS_QUERY_KEY &&
      queryKey[2].includes(postId),
  );
};

export const PostsDetailsContextProvider = ({
  spaceId,
  perPagePostIds = [],
  isSharedPostView = false,
  queryOptions = {},
  queryContext = DEFAULT_POST_DETAILS_QUERY_CONTEXT,
  children,
}) => {
  const queryClient = useQueryClient();
  const currentCommunityMember = useCurrentCommunityMember();
  const postIdsPerQueryKey = {};

  const buildQueryKey = ({ postIds }) => [
    queryContext,
    POST_DETAILS_QUERY_KEY,
    postIds,
  ];

  const postDetailsQuery = useQueries(
    perPagePostIds.map(postIds => {
      const joinedPostIds = postIds.sort().join(",");
      const params = { post_ids: joinedPostIds };
      if (spaceId) {
        params.space_id = spaceId;
      }
      if (isSharedPostView) {
        params.for_shared_post_view = true;
      }
      const postDetailsUrl = internalApi.postDetails.index({
        params: params,
      });
      postIdsPerQueryKey[postDetailsUrl] = postIds;

      const queryKey = buildQueryKey({ postIds });
      return {
        queryFn: () => reactQueryGet(postDetailsUrl),
        queryKey,
        ...queryOptions,
      };
    }),
  );

  const allPostDetails = Object.fromEntries(
    perPagePostIds
      .flat()
      .map(postId => [postId, { data: null, isLoading: true }]),
  );

  postDetailsQuery.forEach(({ data, isLoading }) => {
    data?.forEach(postDetail => {
      allPostDetails[postDetail.id] = { data: postDetail, isLoading };
    });
  });

  const refetchPostDetails = postId => {
    const matchingQueryKeys = findMatchingQueryKeysForPostId({
      postId,
      queryClient,
    });
    matchingQueryKeys.forEach(queryKey => {
      queryClient.invalidateQueries(queryKey);
    });
  };

  const updatePostDetailsQueryCache = (postId, updates) => {
    const matchingQueryKeys = findMatchingQueryKeysForPostId({
      postId,
      queryClient,
    });
    matchingQueryKeys.forEach(queryKey => {
      queryClient.setQueryData(queryKey, oldData =>
        oldData.map(postDetail => {
          if (postDetail.id === postId) {
            return { ...postDetail, ...updates };
          }
          return postDetail;
        }),
      );
    });
  };

  const onLikeToggle = (postDetailsData, value) => {
    if (!currentCommunityMember || !postDetailsData) {
      return;
    }

    let postUpdates = {};
    if (value) {
      postUpdates = {
        is_liked_by_current_user: value,
        sample_user_likes_community_members: [
          ...postDetailsData.sample_user_likes_community_members,
          currentCommunityMember,
        ],
        user_likes_count: postDetailsData.user_likes_count + 1,
      };
    } else {
      postUpdates = {
        is_liked_by_current_user: value,
        sample_user_likes_community_members:
          postDetailsData.sample_user_likes_community_members.filter(
            cm => cm.id !== currentCommunityMember.id,
          ),
        user_likes_count: postDetailsData.user_likes_count - 1,
      };
    }

    updatePostDetailsQueryCache(postDetailsData.id, postUpdates);
    queryClient.removeQueries(["post-likes-modal", postDetailsData.id]);
  };

  const value = {
    allPostDetails,
    onLikeToggle,
    refetchPostDetails,
    updatePostDetailsQueryCache,
  };
  return (
    <PostsDetailsContext.Provider value={value}>
      {children}
    </PostsDetailsContext.Provider>
  );
};

export const usePostDetails = postId => {
  const postDetailsContext = useContext(PostsDetailsContext);

  if (!postId || !postDetailsContext.allPostDetails[postId]) {
    return {};
  }

  const { data, isLoading } = postDetailsContext.allPostDetails[postId];

  return {
    isLoading,
    postDetails: data,
    onLikeToggle: value => postDetailsContext.onLikeToggle(data, value),
    refetchPostDetails: () => postDetailsContext.refetchPostDetails(postId),
    updatePostDetailsQueryCache: updates =>
      postDetailsContext.updatePostDetailsQueryCache(postId, updates),
  };
};

PostsDetailsContextProvider.propTypes = {
  spaceId: PropTypes.number,
  perPagePostIds: PropTypes.array,
  isSharedPostView: PropTypes.bool,
  queryOptions: PropTypes.object,
  queryContext: PropTypes.string,
  children: PropTypes.any,
};
