import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const VERY_PERI_COLORS: PartialTheme = {
  light: {
    "--brand": "#4A54B3",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#CBC6F0",
    "--color-community-header-background": "#CBC6F0",
    "--color-community-sidebar-text": "#161B54",
    "--color-community-header-text": "#161B54",
    "--color-community-sidebar-active-background": "#4A54B3",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#AFABD6",
    "--color-community-sidebar-hover-background": "#AFABD6",
    "--color-online-background": "#F56CF3",
    "--color-community-sidebar-badge-background": "#5467CE",
    "--color-community-header-active-text": "#161B54",
    "--color-community-header-active-background": "#AFABD6",
  },
  dark: {
    "--brand": "#636ED6",
    "--brand-button-text": "#FFFFFF",
    "--color-community-sidebar-background": "#25242B",
    "--color-community-header-background": "#25242B",
    "--color-community-sidebar-text": "#FFFFFF",
    "--color-community-header-text": "#FFFFFF",
    "--color-community-sidebar-active-background": "#636ED6",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#43464C",
    "--color-community-sidebar-hover-background": "#43464C",
    "--color-online-background": "#F56CF3",
    "--color-community-sidebar-badge-background": "#5467CE",
    "--color-community-header-active-text": "#FFFFFF",
    "--color-community-header-active-background": "#43464C",
  },
};

export const VERY_PERI_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...VERY_PERI_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...VERY_PERI_COLORS.dark,
  },
};
