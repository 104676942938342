import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const SWEET_TOOTH_COLORS: PartialTheme = {
  light: {
    "--brand": "#FFC2D0",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FFF8E0",
    "--color-community-header-text": "#550357",
    "--color-community-sidebar-background": "#FFF8E0",
    "--color-community-sidebar-text": "#550357",
    "--color-community-sidebar-active-background": "#FFC2D0",
    "--color-community-active-item-text-color": "#550357",
    "--color-community-header-hover-background": "#FFEDC7",
    "--color-community-sidebar-hover-background": "#FFEDC7",
    "--color-online-background": "#F04386",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#550357",
    "--color-community-header-active-background": "#FFEDC7",
  },
  dark: {
    "--brand": "#FFC2D0",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FFF8E0",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FFF8E0",
    "--color-community-sidebar-active-background": "#FFC2D0",
    "--color-community-active-item-text-color": "#550357",
    "--color-community-header-hover-background": "#4D5FAB",
    "--color-community-sidebar-hover-background": "#4D5FAB",
    "--color-online-background": "#F04386",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#FFF8E0",
    "--color-community-header-active-background": "#4D5FAB",
  },
};

export const SWEET_TOOTH_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...SWEET_TOOTH_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...SWEET_TOOTH_COLORS.dark,
  },
};
