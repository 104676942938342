import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const list = ({ params = {} }: any) =>
  reactQueryGet(internalApi.invitationLinks.index({ params }));

const get = ({ id }: any) =>
  reactQueryGet(internalApi.invitationLinks.get({ id }));

const create = ({ invitationLink, paywall }: any) =>
  reactQueryPost(internalApi.invitationLinks.create(), {
    invitation_link: invitationLink,
    paywall: paywall,
  });

const update = ({ id, invitationLink, paywall }: any) =>
  reactQueryPut(internalApi.invitationLinks.update({ id }), {
    invitation_link: invitationLink,
    paywall: paywall,
  });

const revoke = ({ id }: any) =>
  reactQueryPatch(internalApi.invitationLinks.revoke({ id }));

const reactivate = ({ id }: any) =>
  reactQueryPatch(internalApi.invitationLinks.reactivate({ id }));

const destroy = ({ id }: any) =>
  reactQueryDelete(internalApi.invitationLinks.destroy({ id }));

export const invitationLinksApi = {
  list,
  get,
  create,
  update,
  revoke,
  reactivate,
  destroy,
};
