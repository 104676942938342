import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useFrame } from "react-frame-component";

export const useLocalStorageState = <T = unknown>(
  key: string,
  defaultValue?: T,
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  const { window } = useFrame();

  const [value, setValue] = useState(() => {
    let currentValue: T | undefined;

    try {
      if (!window) {
        throw new Error("No window object found");
      }

      currentValue = JSON.parse(
        window.localStorage.getItem(key) || String(defaultValue),
      );
    } catch (error) {
      console.error("Could not read from localStorage:", error);
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    try {
      if (!window) {
        throw new Error("No window object found");
      }
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Could not write to localStorage:", error);
    }
  }, [window, value, key]);

  return [value, setValue];
};
