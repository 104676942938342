import { absolutePath } from "./absolutePath";

export const spaceMembers = {
  list: ({ spaceId, params = {} }) =>
    absolutePath(`spaces/${spaceId}/space_members`, params),
  show: ({ spaceId, memberId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  update: ({ spaceId, memberId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  sidebar: ({ spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/space_members/sidebar`, params),
  leaveSpace: ({ spaceId, memberId, params = {} }) =>
    absolutePath(`spaces/${spaceId}/space_members/${memberId}`, params),
  joinSpace: ({ spaceId, params = {} }) =>
    absolutePath(`spaces/${spaceId}/space_members`, params),
};
