import { absolutePath } from "./absolutePath";

export const paywallCoupons = {
  index: ({ params = {} } = {}) => absolutePath("paywalls/coupons", params),
  create: ({ params = {} } = {}) => absolutePath("paywalls/coupons/", params),
  update: ({ paywallCouponId, params = {} } = {}) =>
    absolutePath(`paywalls/coupons/${paywallCouponId}`, params),
  destroy: ({ paywallCouponId } = {}) =>
    absolutePath(`paywalls/coupons/${paywallCouponId}`),
  calculateDiscount: ({ params = {} } = {}) =>
    absolutePath("paywalls/coupons/calculate_discount", params),
};
