import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const BEACH_CITY_COLORS: PartialTheme = {
  light: {
    "--brand": "#EF9072",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#FEF3ED",
    "--color-community-header-text": "#3E3747",
    "--color-community-sidebar-background": "#FEF3ED",
    "--color-community-sidebar-text": "#3E3747",
    "--color-community-sidebar-active-background": "#EF9072",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#FFEADE",
    "--color-community-sidebar-hover-background": "#FFEADE",
    "--color-online-background": "#17A7BB",
    "--color-community-sidebar-badge-background": "#4BABAC",
    "--color-community-header-active-text": "#3E3747",
    "--color-community-header-active-background": "#FFEADE",
  },
  dark: {
    "--brand": "#FF8761",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FFFFFF",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FFFFFF",
    "--color-community-sidebar-active-background": "#C76A4D",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#3A3A3A",
    "--color-community-sidebar-hover-background": "#3A3A3A",
    "--color-online-background": "#CC4B22",
    "--color-community-sidebar-badge-background": "#01F6F8",
    "--color-community-header-active-text": "#FFFFFF",
    "--color-community-header-active-background": "#3A3A3A",
  },
} as const;

export const BEACH_CITY_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...BEACH_CITY_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...BEACH_CITY_COLORS.dark,
  },
};
