import { absolutePath } from "./absolutePath";

export const memberActivityScore = {
  index: ({
    params = {},
  }: {
    params?: {
      community_member_ids?: number[];
    };
  } = {}) => absolutePath("activity_scores/activity_scores", params),
};
