import type { Community } from "@circle-react/types";
import type { CommunityMember } from "@circle-react/types/CommunityMember";
import type { CommunitySettings } from "@circle-react/types/CommunitySettings";

export const isCommunityAdmin = (communityMember?: CommunityMember) =>
  Boolean(communityMember?.is_admin);

export const isMemberDirectoryVisible = (
  communityMember?: CommunityMember,
  communitySetting?: CommunitySettings,
) =>
  Boolean(
    communityMember &&
      (communitySetting?.public_member_directory ||
        isCommunityAdmin(communityMember)),
  );

export const isAllEventsDirectoryVisible = (
  communityMember?: CommunityMember,
  communitySetting?: CommunitySettings,
) =>
  Boolean(
    communitySetting?.public_events_page || isCommunityAdmin(communityMember),
  );

export const isCoursesDirectoyVisible = (
  communityMember?: CommunityMember,
  currentCommunity?: Community,
) =>
  Boolean(
    currentCommunity?.policies?.can_view_courses_directory ||
      isCommunityAdmin(communityMember),
  );

export const isLeaderboardVisible = (currentCommunity?: Community) =>
  Boolean(currentCommunity?.policies?.can_view_leaderboard);

export const isCommunityModerator = (communityMember?: CommunityMember) =>
  Boolean(communityMember?.is_moderator);

export const isCommunityModeratorButNotAdmin = (
  communityMember?: CommunityMember,
) =>
  isCommunityModerator(communityMember) && !isCommunityAdmin(communityMember);

export const isProfileConfirmed = (communityMember?: CommunityMember) =>
  Boolean(communityMember?.profile_confirmed_at);

export const isMember = (role: string) => role === "Member";

export const canCreateLive = (communityMember?: CommunityMember) =>
  Boolean(communityMember?.policies?.can_create_live);
