import { fetchRequest } from "../helpers/backendRequestHelpers";
import { userLikes } from "../helpers/urlHelpers";

// TODO: crete and use internal api.
const create = ({
  userLikeableId: user_likeable_id,
  userLikableType: user_likeable_type,
  ...fetchRequestOptions
}) =>
  fetchRequest(userLikes.create(), {
    method: "POST",
    ...fetchRequestOptions,
    body: JSON.stringify({
      user_likeable_type,
      user_likeable_id,
    }),
  });

const destroy = ({
  userLikeableId: user_likeable_id,
  userLikableType: user_likeable_type,
  ...fetchRequestOptions
}) =>
  fetchRequest(userLikes.destroy(), {
    method: "DELETE",
    ...fetchRequestOptions,
    body: JSON.stringify({
      user_likeable_type,
      user_likeable_id,
    }),
  });

export const userLikeApi = {
  create,
  destroy,
};
