export const NotificationsPollingInterval = 60000;
export const CommunitySwitcherNotificationsPollingInterval = 300000;
export const NUMBER_OF_NOTIFICATION_IN_MENU = 20;

// This is a write only key and hence we can keep it in FE, there is not other option.
// https://github.com/bugsnag/bugsnag-js/issues/595#issuecomment-514578712
export const BUGSNAG_API_KEY = "4537f634332141933aabced249871e5a";
export const BUGSNAG_ENABLED_RELEASE_STAGES = ["production", "staging"];

export const staticAssetUrl = (path: string) =>
  path ? `${window.CDN_HOST}/${path}` : window.CDN_HOST;

export const NON_CUSTOM_EMAIL_DOMAINS_LIST = [
  "gmail.com",
  "hotmail.com",
  "outlook.com",
  "yahoo.com",
  "aol.com",
  "yandex.com",
  "icloud.com",
  "mail.com",
  "protonmail.com",
  "zoho.com",
  "aim.com",
] as const;

export const BASE_LOCAL_STORAGE_KEY = window.BASE_LOCAL_STORAGE_KEY;

const appendBaseKeyToLocalStorage = (key: string) =>
  `${BASE_LOCAL_STORAGE_KEY}-${key}`;

// NOTE: we want to limit our local storage useage and remove this code once we upgrade
export const LOCALSTORAGE_KEYS = {
  SpaceGroupsContextProvider: appendBaseKeyToLocalStorage(
    "SpaceGroupsContextProvider",
  ),
  SpacesContextProvider: appendBaseKeyToLocalStorage("SpacesContextProvider"),
  PunditUserContext: appendBaseKeyToLocalStorage("PunditUserContext"),
} as const;

export const getIconUrl = (iconFile: string) =>
  staticAssetUrl(`app-assets/icons/${iconFile}`);
