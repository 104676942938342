import {
  reactQueryGet,
  reactQueryPut,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const show = id => reactQueryGet(internalApi.mediaSettings.show({ id }));

const update = (id, params) =>
  reactQueryPut(internalApi.mediaSettings.update({ id }), params);

export const mediaSettingsApi = {
  show,
  update,
};
