import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers/internalApi";

const addReaction = body =>
  reactQueryPost(internalApi.reactions.reactions(), body);

const removeReaction = body =>
  reactQueryDelete(internalApi.reactions.reactions(), body);

const fetchCommunityMemberDetails = params =>
  reactQueryGet(internalApi.reactions.communityMembers(params));

export const reactionsApi = {
  addReaction,
  removeReaction,
  fetchCommunityMemberDetails,
};
