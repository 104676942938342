import { absolutePath } from "./absolutePath";

export const chatThreads = {
  index: ({ params = {} } = {}) => absolutePath(`chat_threads`, params),
  show: ({ id, params = {} } = {}) =>
    absolutePath(`chat_threads/${id}`, params),
  unreadChatThreads: ({ params = {} } = {}) =>
    absolutePath(`chat_threads/unread_chat_threads`, params),
  markAsRead: ({ id, params = {} }) =>
    absolutePath(`chat_threads/${id}/mark_as_read`, params),
};
