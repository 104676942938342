import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const MOODY_COLORS: PartialTheme = {
  light: {
    "--brand": "#617276",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#BBCDD4",
    "--color-community-header-text": "#122127",
    "--color-community-sidebar-background": "#BBCDD4",
    "--color-community-sidebar-text": "#122127",
    "--color-community-sidebar-active-background": "#617276",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#95B1BC",
    "--color-community-sidebar-hover-background": "#95B1BC",
    "--color-online-background": "#5471D7",
    "--color-community-sidebar-badge-background": "#617276",
    "--color-community-header-active-text": "#122127",
    "--color-community-header-active-background": "#95B1BC",
  },
  dark: {
    "--brand": "#94B0B6",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#4D585C",
    "--color-community-header-text": "#DDEBEE",
    "--color-community-sidebar-background": "#4D585C",
    "--color-community-sidebar-text": "#DDEBEE",
    "--color-community-sidebar-active-background": "#94B0B6",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#57666B",
    "--color-community-sidebar-hover-background": "#57666B",
    "--color-online-background": "#B5C1EB",
    "--color-community-sidebar-badge-background": "#617276",
    "--color-community-header-active-text": "#DDEBEE",
    "--color-community-header-active-background": "#57666B",
  },
};

export const MOODY_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...MOODY_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...MOODY_COLORS.dark,
  },
};
