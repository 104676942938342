import { absolutePath } from "./absolutePath";

export const events = {
  create: ({ spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events`, params),
  update: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  destroy: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  show: ({ spaceId, slug, params = {} }) =>
    absolutePath(`spaces/${spaceId}/events/${slug}`, params),
  attendees: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/event_attendees`, params),
  pin: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/pin`, params),
  duplicate: ({ spaceId, slug, params }) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/duplicate`, params),
  allEvents: ({ params }) => absolutePath(`events/community_events`, params),
  addToCalendar: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/add_to_calendar`, params),
};
