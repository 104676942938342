import { absolutePath } from "./absolutePath";

export const spaceGroups = {
  index: params => absolutePath("space_groups", params),
  show: ({ id, params = {} } = {}) =>
    absolutePath(`space_groups/${id}`, params),
  create: ({ params = {} } = {}) => absolutePath("space_groups", params),
  update: ({ id, params = {} } = {}) =>
    absolutePath(`space_groups/${id}`, params),
  destroy: ({ id, params = {} } = {}) =>
    absolutePath(`space_groups/${id}`, params),
  members: ({ spaceGroupId, params }) =>
    absolutePath(`space_groups/${spaceGroupId}/space_group_members`, params),
  deleteMember: ({ spaceGroupId, memberId, params = {} }) =>
    absolutePath(
      `space_groups/${spaceGroupId}/space_group_members/${memberId}`,
      params,
    ),
  exportMembers: ({ id, params = {} } = {}) =>
    absolutePath(`space_groups/${id}/space_group_members/export`, params),
  addMemberToSpaceGroup: ({ spaceGroupId, params = {} }) =>
    absolutePath(
      `space_groups/${spaceGroupId}/space_group_members/bulk_create`,
      params,
    ),
};
