import { useMutation, useQuery, useQueryClient } from "react-query";
import { supportWidgetApi } from "@circle-react/api/supportWidgetApi";
import { useSupportWidgetContext } from "./SupportWidgetContext";
import { FIFTEEN_MINUTES, FIVE_MINUTES, ONE_HOUR } from "./constants";
import { getLocalStorage, setLocalStorage } from "./utils";

const handleOnError = () => {
  setLocalStorage({
    ...getLocalStorage(),
    location: "/",
  });
};

export const useListTutorialPosts = () =>
  useQuery(["posts", "tutorials"], () => supportWidgetApi.listTutorialPosts(), {
    staleTime: ONE_HOUR,
    retry: false,
  });

export const useListNews = () =>
  useQuery(["news"], () => supportWidgetApi.listNews(), {
    staleTime: ONE_HOUR,
    retry: false,
  });

export const useShowNews = id => {
  const queryClient = useQueryClient();
  return useQuery(["news", id], () => supportWidgetApi.showPost(id), {
    onError: handleOnError,
    retry: false,
    staleTime: FIFTEEN_MINUTES,
    initialData: () =>
      queryClient
        .getQueryData(["news"])
        ?.records?.find(d => d.id?.toString() === id),
  });
};

export const useShowPinnedPost = () =>
  useQuery(["pinnedPost"], () => supportWidgetApi.showPinnedPost(), {
    staleTime: ONE_HOUR,
    retry: false,
  });

export const useListSpaceGroups = () =>
  useQuery("space-groups", () => supportWidgetApi.spaceGroups(), {
    staleTime: ONE_HOUR,
    retry: false,
  });

export const useListPosts = (spaceId, query) =>
  useQuery(
    ["posts", "list", spaceId, query],
    () =>
      supportWidgetApi.listPosts({
        spaceId,
        searchText: query,
      }),
    { retry: false },
  );

export const useShowPost = id =>
  useQuery(["posts", "details", id], () => supportWidgetApi.showPost(id), {
    onError: handleOnError,
    retry: false,
    staleTime: FIFTEEN_MINUTES,
  });

export const useCheckConversations = currentCommunityMember =>
  useQuery(
    ["conversations", "check"],
    () => supportWidgetApi.checkConversations(),
    {
      retry: false,
      staleTime: FIVE_MINUTES,
      enabled: currentCommunityMember?.is_admin || false,
    },
  );

export const useListConversations = () =>
  useQuery(
    ["conversations", "list"],
    () => supportWidgetApi.listConversations(),
    { retry: false, staleTime: FIVE_MINUTES },
  );

export const useShowConversation = id => {
  const { setUnreadConversations } = useSupportWidgetContext();
  const queryClient = useQueryClient();

  return useQuery(
    ["conversations", "show", id],
    () =>
      supportWidgetApi.showConversation({
        id,
      }),
    {
      onSuccess: data => {
        const conversationId = data.conversation.id;

        setUnreadConversations(unreadConversations =>
          unreadConversations.filter(id => id !== conversationId),
        );

        if (queryClient.getQueryData(["conversations", "list"])) {
          queryClient.setQueryData(
            ["conversations", "list"],
            oldConversations => ({
              conversations: oldConversations.conversations.map(
                conversation => {
                  if (conversation.id === conversationId) {
                    return {
                      ...conversation,
                      unread: false,
                    };
                  }
                  return conversation;
                },
              ),
            }),
          );
        }
      },
      onError: handleOnError,
      retry: false,
    },
  );
};

export const useListEvents = () =>
  useQuery(["events"], () => supportWidgetApi.listEvents(), {
    staleTime: ONE_HOUR,
    retry: false,
  });

export const useCreateConversation = ({
  onSuccess,
  onError,
  attachments,
  source,
  chatBotSessionId,
}) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ text, area, type, is_important }) =>
      supportWidgetApi
        .createConversation({
          text,
          area,
          type,
          is_important,
          source,
          chatBotSessionId,
          zendeskUploadIds: attachments.map(
            attachment => attachment.zendeskUploadId,
          ),
          submitPageUrl: window.location.href,
          viewportHeight:
            window.innerHeight || document.documentElement.clientHeight,
          viewportWidth:
            window.innerWidth || document.documentElement.clientWidth,
        })
        .then(response => response.json()),
    {
      onSuccess: data => {
        if (queryClient.getQueryData(["conversations", "list"])) {
          queryClient.setQueryData(
            ["conversations", "list"],
            oldConversations => ({
              conversations: [data.ticket, ...oldConversations.conversations],
            }),
          );
        }

        onSuccess(data.ticket);
      },
      onError: onError,
    },
  );
};

export const useCreateChatMessage = ({ onSuccess, onError }) =>
  useMutation(
    ({ message, sessionId, productArea }) =>
      supportWidgetApi
        .createChatMessage({
          message,
          sessionId,
          productArea,
        })
        .then(response => response.json()),
    {
      onSuccess,
      onError,
    },
  );

export const useCreateLiveChatMessage = ({ onSuccess, onError }) =>
  useMutation(
    ({ message, sessionId }) =>
      supportWidgetApi
        .createLiveChatMessage({
          message,
          sessionId,
        })
        .then(response => response.json()),
    {
      onSuccess,
      onError,
    },
  );

export const useGetLiveChatStatus = ({ enabled }) =>
  useQuery(["liveChatStatus"], () => supportWidgetApi.getLiveChatStatus(), {
    enabled,
    retry: false,
    staleTime: 0,
    cacheTime: 0,
  });
