import { absolutePath } from "../absolutePath";

interface InvoiceType {
  show: () => string;
  upcomingInvoice: () => string;
}

export const invoice: InvoiceType = {
  show: () => absolutePath("account_payment_processors/invoices"),
  upcomingInvoice: () =>
    absolutePath("account_payment_processors/invoices/upcoming_invoice"),
};
