import { absolutePath } from "../../absolutePath";
import { affiliates } from "./affiliates";

export const paywalls = {
  exports: {
    create: () => absolutePath("admin/paywalls/exports"),
  },
  subscriptions: {
    index: ({ params = {} } = {}) =>
      absolutePath("admin/paywalls/subscriptions", params),
    create: () => absolutePath("admin/paywalls/subscriptions"),
    refundOptions: ({ subscriptionProcessorId } = {}) =>
      absolutePath(
        `admin/paywalls/subscriptions/${subscriptionProcessorId}/refund_options`,
      ),
    cancellations: ({ subscriptionProcessorId, params = {} } = {}) =>
      absolutePath(
        `admin/paywalls/subscriptions/${subscriptionProcessorId}/cancellations`,
        params,
      ),
    stats: {
      index: ({ params = {} } = {}) =>
        absolutePath("admin/paywalls/subscriptions/stats", params),
    },
    coupons: {
      create: ({ subscriptionProcessorId } = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons`,
        ),
      delete: ({ subscriptionProcessorId } = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons`,
        ),
      preview: ({ subscriptionProcessorId, couponCode } = {}) =>
        absolutePath(
          `admin/paywalls/subscriptions/${subscriptionProcessorId}/coupons/preview`,
          couponCode ? { coupon_code: couponCode } : {},
        ),
    },
  },
  charges: {
    index: ({ params = {} } = {}) =>
      absolutePath("admin/paywalls/charges", params),
    refunds: ({ chargeProcessorId, params = {} } = {}) =>
      absolutePath(
        `admin/paywalls/charges/${chargeProcessorId}/refunds`,
        params,
      ),
    stats: {
      index: ({ params = {} } = {}) =>
        absolutePath("admin/paywalls/charges/stats", params),
    },
  },
  taxSettings: {
    show: () => absolutePath("admin/paywalls/tax_settings"),
    update: () => absolutePath("admin/paywalls/tax_settings"),
  },
  paymentSettings: {
    show: () => absolutePath("admin/paywalls/payment_settings"),
    update: () => absolutePath("admin/paywalls/payment_settings"),
  },
  stats: {
    index: ({ params = {} } = {}) =>
      absolutePath(`admin/paywalls/stats`, params),
  },
  affiliates,
};
