import { toQueryString } from "../toQueryString";

const path = (path: any, params: any = {}) =>
  `${path}?${toQueryString(params)}`;

const externalBaseUrl = "/marketing_emails/marketing_email_preference";
const internalBaseUrl = "/internal_api/marketing_email_preference";

export type AccessType = "internal" | "external";

const getBaseUrl = (accessType: AccessType) =>
  accessType === "internal" ? internalBaseUrl : externalBaseUrl;

export const marketingEmailPreferences = {
  show: (accessType: AccessType, { params = {} } = {}) =>
    path(getBaseUrl(accessType), params),
  upsert: (accessType: AccessType, { params = {} } = {}) =>
    path(`${getBaseUrl(accessType)}/upsert`, params),
};
