import type { MarketingEmailAddressAttributes } from "@circle-react/components/SettingsApp/Emails/components/BroadcastSettings/interfaces";
import { absolutePath } from "./absolutePath";

export const marketingEmailAddressSettings = {
  show: () => absolutePath(`/admin/marketing_email_address_settings`),
  upsert: () => absolutePath(`/admin/marketing_email_address_settings/upsert`),
  footerHtml: (payload: MarketingEmailAddressAttributes) =>
    absolutePath(
      `/admin/marketing_email_address_settings/footer_html`,
      payload,
    ),
};
