import { absolutePath } from "./absolutePath";
import { affiliates } from "./paywalls/affiliates";
import { communityMembers } from "./paywalls/communityMembers";

export const paywalls = {
  index: ({ params = {} } = {}) => absolutePath("paywalls", params),
  create: ({ params = {} } = {}) => absolutePath("paywalls/", params),
  update: ({ paywallId, params = {} } = {}) =>
    absolutePath(`paywalls/${paywallId}`, params),
  delete: ({ paywallId } = {}) => absolutePath(`paywalls/${paywallId}`),
  show: ({ paywallId, params = {} } = {}) =>
    absolutePath(`paywalls/${paywallId}`, params),
  communityMembers,
  updateMemberAccess: ({ paywallId, params = {} }) =>
    absolutePath(`paywalls/${paywallId}/update_member_access`, params),
  affiliates,
};
