import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";

export const useSpaceGroupResource = (spaceGroupId?: number) => {
  const { data, isLoading, refetch, isError } = useQuery<
    { name: string } | undefined
  >(internalApi.spaceGroups.show({ id: spaceGroupId }), {
    enabled: !!spaceGroupId,
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};
