import { toQueryString } from "./toQueryString";

export const memberProfileModalPaths = {
  profile: "/account",
  notifications: "/account/notifications",
  authentication: "/account/authentication",
  billing: "/account/billing",
  affiliate: "/account/affiliate",
};

interface PathWithState {
  pathname: string;
  state?: {
    from: any;
  };
}

const buildMemberProfilePath = (
  path: string,
  prevLocation?: any,
  queryParams?: string,
): PathWithState => {
  const prevLocationObject = prevLocation
    ? { state: { from: prevLocation } }
    : {};

  const withQueryParams = queryParams ? `/${queryParams}` : "";

  return {
    pathname: `${path}${withQueryParams}`,
    ...prevLocationObject,
  };
};

export const memberProfileModalPathsWithState = {
  profile: (prevLocation?: any, queryParams?: string) =>
    buildMemberProfilePath(
      memberProfileModalPaths.profile,
      prevLocation,
      queryParams,
    ),
  notifications: (prevLocation: any, queryParams?: string) =>
    buildMemberProfilePath(
      memberProfileModalPaths.notifications,
      prevLocation,
      queryParams,
    ),
  authentication: (prevLocation?: any, queryParams?: string) =>
    buildMemberProfilePath(
      memberProfileModalPaths.authentication,
      prevLocation,
      queryParams,
    ),
  billing: (prevLocation?: any, queryParams?: string) =>
    buildMemberProfilePath(
      memberProfileModalPaths.billing,
      prevLocation,
      queryParams,
    ),
  affiliate: (prevLocation?: any, queryParams?: string) =>
    buildMemberProfilePath(
      memberProfileModalPaths.affiliate,
      prevLocation,
      queryParams,
    ),
};

interface MemberProfileArgs {
  params?: Record<string, any>;
}

/** @deprecated These links are deprecated, use the memberProfileModalPathsWithState */
export const communityMemberProfileSettingsPath_DEPRECATED = ({
  params = {},
}: MemberProfileArgs) => `/settings/profile?${toQueryString(params)}`;
