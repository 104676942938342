import { absolutePath } from "./absolutePath";

export const liveStreams = {
  index: ({ params = {} } = {}) => absolutePath("live_streams/rooms", params),
  liveNow: ({ params = {} } = {}) =>
    absolutePath("live_streams/rooms/live_now", params),
  create: ({ params = {} } = {}) => absolutePath("live_streams/rooms", params),
  update: ({ id, params = {} }) =>
    absolutePath(`live_streams/rooms/${id}`, params),
  show: ({ id, params = {} }) =>
    absolutePath(`live_streams/rooms/${id}`, params),
  goLive: ({ id, params = {} }) =>
    absolutePath(`live_streams/rooms/${id}/go_live`, params),
  join: ({ id, params = {} }) =>
    absolutePath(`live_streams/rooms/${id}/participants`, params),
  leave: ({ id, participantId, params = {} }) =>
    absolutePath(
      `live_streams/rooms/${id}/participants/${participantId}`,
      params,
    ),
  participants: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/participants`, params),
  inviteParticipants: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/participants/invite`, params),
  prejoinToken: ({ roomId, params = {} }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/prejoin_token`,
      params,
    ),
  addCohosts: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts`, params),
  getCohosts: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts`, params),
  removeCohost: ({ id, roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/cohosts/${id}`, params),
  addModerator: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/moderators`, params),
  changeRole: ({ roomId, participantId }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/${participantId}/change_role`,
    ),
  removeParticipant: ({ roomId, participantId, params = {} }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/participants/${participantId}/remove_participant`,
      params,
    ),
  downloadParticipantsCSV: ({ roomId }) =>
    absolutePath(`live_streams/rooms/${roomId}/participants/download_csv`),
  chatTranscript: ({ id }) =>
    absolutePath(`live_streams/rooms/${id}/transcripts`),
  endStream: ({ id }) => absolutePath(`live_streams/rooms/${id}`),
  endStreamDelayed: ({ id }) =>
    absolutePath(`live_streams/rooms/${id}/destroy_async`),
  leaveFeedback: ({ id, participantId }) =>
    absolutePath(
      `live_streams/rooms/${id}/participants/${participantId}/leave_feedback`,
    ),
  createRecorder: ({ id, params = {} }) =>
    absolutePath(`live_streams/rooms/${id}/recorders`, params),
  configurations: ({ params = {} } = {}) =>
    absolutePath("live_streams/configurations", params),
  usage: ({ params = {} } = {}) => absolutePath("live_streams/usage", params),
  usageLimit: ({ params = {} } = {}) =>
    absolutePath("live_streams/usage/limit_info", params),
  deleteRoomRecording: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/recordings/${id}`),
  // QA Session
  updateQASession: ({ roomId }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/session`),
  // QA Questions
  getQAQuestions: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions`, params),
  createQAQuestion: ({ roomId }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions`),
  removeQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}`),
  approveQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/approve`),
  dismissQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/dismiss`),
  markQAQuestionAsAnswered: ({ roomId, id }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/mark_as_answered`,
    ),
  bringBackQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/bring_back`),
  upvoteQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/upvote`),
  removeUpvoteQAQuestion: ({ roomId, id }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/remove_upvote`,
    ),
  spotlightQAQuestion: ({ roomId, id }) =>
    absolutePath(`live_streams/rooms/${roomId}/qa/questions/${id}/spotlight`),
  removeSpotlightQAQuestion: ({ roomId, id }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${id}/remove_spotlight`,
    ),
  // QA Replies
  getQAReplies: ({ roomId, questionId, params = {} }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies`,
      params,
    ),
  createQAReply: ({ roomId, questionId }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies`,
    ),
  pinQAReply: ({ roomId, questionId, id }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies/${id}/pin`,
    ),
  unpinQAReply: ({ roomId, questionId, id }) =>
    absolutePath(
      `live_streams/rooms/${roomId}/qa/questions/${questionId}/replies/${id}/unpin`,
    ),
  // Spotlight
  spotlightItems: ({ roomId, params = {} }) =>
    absolutePath(`live_streams/rooms/${roomId}/spotlight_items`, params),
};
