import {
  fetchRequest,
  reactQueryGet,
  reactQueryPatch,
} from "../helpers/backendRequestHelpers";
import { internalApi } from "../helpers/urlHelpers";

const update = (chatRoom, params = {}) => {
  const { uuid, chat_room } = chatRoom;
  return fetchRequest(internalApi.chatRooms.update({ uuid }), {
    method: "PATCH",
    body: JSON.stringify({ chat_room: chat_room }),
    ...params,
  });
};

const destroy = (chatRoom, params = {}) => {
  const { uuid } = chatRoom;
  return fetchRequest(internalApi.chatRooms.destroy({ uuid }), {
    method: "DELETE",
    ...params,
  });
};

const getChatRoomCurrentParticipant = uuid =>
  reactQueryGet(internalApi.chatRooms.currentParticipant({ uuid }));

const pinMessage = chatRoom => {
  const { uuid, chat_room } = chatRoom;
  return reactQueryPatch(internalApi.chatRooms.pinMessage({ uuid }), {
    chat_room: chat_room,
  });
};

const markAsUnread = chatRoom => {
  const {
    uuid,
    chat_room: { message_id: id },
  } = chatRoom;
  return reactQueryPatch(
    internalApi.chatRoomMessages.markAsUnread({ uuid, id }),
  );
};

export const chatRoomApi = {
  update,
  destroy,
  getChatRoomCurrentParticipant,
  pinMessage,
  markAsUnread,
};
