import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useRouteChangeEvent = () => {
  const { pathname } = useLocation();

  const fireRouteChangeEvent = useCallback(() => {
    const event = new CustomEvent("circle:route-change", {
      bubbles: true,
      cancelable: true,
      composed: false,
      detail: {
        path: pathname,
      },
    });

    document.dispatchEvent(event);
  }, [pathname]);

  useEffect(() => {
    fireRouteChangeEvent();
  }, [fireRouteChangeEvent]);
};
