export const authPathsWithRedirect = {
  signIn: () =>
    `/users/sign_in?post_login_redirect=${encodeURIComponent(
      window.location.href,
    )}`,
  signUp: () =>
    `/users/sign_up?post_login_redirect=${encodeURIComponent(
      window.location.href,
    )}`,
};
