import { absolutePath } from "./absolutePath";

export const communityMember = {
  show: ({ memberId }) => absolutePath(`community_members/${memberId}`),
  update: ({ memberId, params = {} } = {}) =>
    absolutePath(`community_members/${memberId}`, params),
  settings: ({ memberId, params = {} } = {}) =>
    absolutePath(`community_members/${memberId}/settings`, params),
  delete: ({ memberId }) => absolutePath(`community_members/${memberId}/`),
};
