import { PropTypes } from "prop-types";
import { noop } from "lodash";
import { QueryClient, QueryClientProvider } from "react-query";

export const mockedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: noop,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: 1,
      retryDelay: 3000,
    },
  },
});

export const MockedQueryClientProvider = ({ children }) => (
  <QueryClientProvider client={mockedQueryClient}>
    {children}
  </QueryClientProvider>
);

MockedQueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
