import { absolutePath } from "./absolutePath";

export const recurringEvents = {
  index: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(`spaces/${spaceId}/events/${slug}/recurring_events`, params),
  rsvp: ({ slug, spaceId, params = {} } = {}) =>
    absolutePath(
      `spaces/${spaceId}/events/${slug}/recurring_events/rsvp`,
      params,
    ),
};
