import type { ReactNode } from "react";
import { useMemo } from "react";
import type { UsageSections } from "./UsageContext";
import { USAGE_SECTIONS, UsageContext } from "./UsageContext";

export interface UsageContextProviderProps {
  section: UsageSections;
  children: ReactNode;
}

export const UsageContextProvider = ({
  section,
  children,
}: UsageContextProviderProps) => {
  const value = useMemo(() => {
    const isAdminUsageContext = section === USAGE_SECTIONS.ADMIN;
    return {
      section,
      isAdminUsageContext,
    };
  }, [section]);

  return (
    <UsageContext.Provider value={value}>{children}</UsageContext.Provider>
  );
};
