import { absolutePath } from "./absolutePath";

export const chatRoomParticipants = {
  index: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants`, params),
  create: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/`, params),
  update: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/${id}`, params),
  destroy: ({ uuid, id, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/participants/${id}`, params),
};
