import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const ESPRESSO_COLORS: PartialTheme = {
  light: {
    "--brand": "#9C431A",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#F5F2ED",
    "--color-community-header-text": "#1C1911",
    "--color-community-sidebar-background": "#F5F2ED",
    "--color-community-sidebar-text": "#1C1911",
    "--color-community-sidebar-active-background": "#9C431A",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#E8E4DA",
    "--color-community-sidebar-hover-background": "#E8E4DA",
    "--color-online-background": "#BD4111",
    "--color-community-sidebar-badge-background": "#504638",
    "--color-community-header-active-text": "#1C1911",
    "--color-community-header-active-background": "#E8E4DA",
  },
  dark: {
    "--brand": "#E35F35",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#2C2E33",
    "--color-community-header-text": "#FFFFFF",
    "--color-community-sidebar-background": "#2C2E33",
    "--color-community-sidebar-text": "#FFFFFF",
    "--color-community-sidebar-active-background": "#D26735",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#E8E4DA",
    "--color-community-sidebar-hover-background": "#3A3A3A",
    "--color-online-background": "#BD4111",
    "--color-community-sidebar-badge-background": "#504638",
    "--color-community-header-active-text": "#FFFFFF",
    "--color-community-header-active-background": "#3A3A3A",
  },
};

export const ESPRESSO_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...ESPRESSO_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...ESPRESSO_COLORS.dark,
  },
};
