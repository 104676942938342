import { DEFAULT_THEME } from "./Default";
import type { PartialTheme, Theme } from "./types";

const NEON_NIGHT_COLORS: PartialTheme = {
  light: {
    "--brand": "#F03964",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#01163F",
    "--color-community-header-text": "#ECE7F6",
    "--color-community-sidebar-background": "#01163F",
    "--color-community-sidebar-text": "#ECE7F6",
    "--color-community-sidebar-active-background": "#F03964",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#012B6B",
    "--color-community-sidebar-hover-background": "#012B6B",
    "--color-online-background": "#F03964",
    "--color-community-sidebar-badge-background": "#7D4183",
    "--color-community-header-active-text": "#ECE7F6",
    "--color-community-header-active-background": "#012B6B",
  },
  dark: {
    "--brand": "#F03964",
    "--brand-button-text": "#FFFFFF",
    "--color-community-header-background": "#141926",
    "--color-community-header-text": "#ECE7F6",
    "--color-community-sidebar-background": "#141926",
    "--color-community-sidebar-text": "#ECE7F6",
    "--color-community-sidebar-active-background": "#F03964",
    "--color-community-active-item-text-color": "#FFFFFF",
    "--color-community-header-hover-background": "#324259",
    "--color-community-sidebar-hover-background": "#324259",
    "--color-online-background": "#F03964",
    "--color-community-sidebar-badge-background": "#7D4183",
    "--color-community-header-active-text": "#ECE7F6",
    "--color-community-header-active-background": "#324259",
  },
};

export const NEON_NIGHT_THEME: Theme = {
  light: {
    ...DEFAULT_THEME.light,
    ...NEON_NIGHT_COLORS.light,
  },
  dark: {
    ...DEFAULT_THEME.dark,
    ...NEON_NIGHT_COLORS.dark,
  },
};
