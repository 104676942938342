import { absolutePath } from "./absolutePath";

export const chatRooms = {
  index: ({ params = {} } = {}) => absolutePath("chat_rooms", params),
  show: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  create: ({ params = {} } = {}) => absolutePath("chat_rooms", params),
  update: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  destroy: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}`, params),
  currentParticipant: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/chat_room_participant`, params),
  markAllAsRead: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/mark_all_as_read`, params),
  unreadChatRooms: ({ params = {} } = {}) =>
    absolutePath("chat_rooms/unread_chat_rooms", params),
  pinMessage: ({ uuid, params = {} } = {}) =>
    absolutePath(`chat_rooms/${uuid}/pin_message`, params),
  fetch: ({ params = {} } = {}) =>
    absolutePath("chat_rooms/retrieve_by_member_ids", params),
};
