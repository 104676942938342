import { toQueryString } from "@circle-react/helpers/urlHelpers/toQueryString";
import type { Params } from "@circle-react/helpers/urlHelpers/toQueryString";

interface ParamsProps {
  params?: Params;
}

interface TaskIdProps {
  params?: Params;
  taskId: string;
}

interface TemplateIdProps {
  params?: Params;
  templateId: string;
}

interface IdProps {
  id: string;
  params?: Params;
}

interface WorkflowIdProps {
  params?: Params;
  workflowId: string;
}

interface WorkflowIdIdProps {
  id: string;
  params?: Params;
  workflowId: string;
}

interface WorkflowIdRunIdProps {
  params?: Params;
  runId: string;
  workflowId: string;
}

interface PaywallIdIdProps {
  id: string;
  params?: Params;
  paywallId: string;
}

const path = (path: string, params: Params = {}) =>
  `${path}?${toQueryString(params)}`;

const triggerFn = ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
  path(`/workflows/api/v1/workflows/${workflowId}/triggers/${id}`, params);

export const workflows = {
  create: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/workflows", params),
  list: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/workflows", params),
  fetch: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}`, params),
  update: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}`, params),
  activate: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}/activate`, params),
  inactivate: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}/inactivate`, params),
  archive: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}/archive`, params),
  duplicate: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}/duplicate`, params),
  delete: ({ id, params = {} }: IdProps) =>
    path(`/workflows/api/v1/workflows/${id}`, params),
  createTrigger: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/triggers`, params),
  updateTrigger: triggerFn,
  deleteTrigger: triggerFn,
  createRun: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/runs`, params),
  fetchRuns: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/runs`, params),
  fetchRun: ({ workflowId, runId, params = {} }: WorkflowIdRunIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/runs/${runId}`, params),
  cancelRun: ({ workflowId, runId, params = {} }: WorkflowIdRunIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/runs/${runId}/cancel`,
      params,
    ),
  fetchTasksInRun: ({ workflowId, runId, params = {} }: WorkflowIdRunIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/runs/${runId}/tasks`,
      params,
    ),
  createAudience: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/audiences`, params),
  updateAudience: ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/audiences/${id}`, params),
  createAction: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/actions`, params),
  updateAction: ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/actions/${id}`, params),
  deleteAction: ({ workflowId, id }: WorkflowIdIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/actions/${id}`),
  upsertActions: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/actions/upsert`, params),
  createOneTimeSchedule: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(`/workflows/api/v1/workflows/${workflowId}/schedule/one_time`, params),
  updateOneTimeSchedule: ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/schedule/one_time/${id}`,
      params,
    ),
  createRecurringSchedule: ({ workflowId, params = {} }: WorkflowIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/schedule/recurring`,
      params,
    ),
  updateRecurringSchedule: ({
    workflowId,
    id,
    params = {},
  }: WorkflowIdIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/schedule/recurring/${id}`,
      params,
    ),
  reschedule: ({ workflowId, id, params = {} }: WorkflowIdIdProps) =>
    path(
      `/workflows/api/v1/workflows/${workflowId}/schedule/reschedule/${id}`,
      params,
    ),
  tasks: ({ params = {} }: ParamsProps) =>
    path(`/workflows/api/v1/tasks`, params),
  taskDetail: ({ taskId, params = {} }: TaskIdProps) =>
    path(`/workflows/api/v1/tasks/${taskId}`, params),
  templates: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/templates", params),
  fetchTemplate: ({ templateId, params = {} }: TemplateIdProps) =>
    path(`/workflows/api/v1/templates/${templateId}`, params),
  createFromTemplate: ({ templateId, params = {} }: TemplateIdProps) =>
    path(`/workflows/api/v1/templates/${templateId}/create_workflow`, params),
  fetchCommunityCurrentUsageStats: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/community_current_usage_stats", params),
  fetchInterpolatedMessage: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/preview_messages/interpolated_message", params),
  fetchDynamicVariables: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/dynamic_variables", params),
  testWebhook: ({ params = {} }: ParamsProps) =>
    path("/workflows/api/v1/webhook_tests", params),
  events: {
    index: ({ params = {} }: ParamsProps) =>
      path("/internal_api/workflows/events", params),
    show: ({ id, params = {} }: IdProps) =>
      path(`/internal_api/workflows/events/${id}`, params),
  },
  posts: {
    show: ({ id, params = {} }: IdProps) =>
      path(`/internal_api/workflows/posts/${id}`, params),
  },
  recurringEventSettings: {
    show: ({ id, params = {} }: IdProps) =>
      path(`/internal_api/workflows/recurring_event_settings/${id}`, params),
  },
  paywallPrices: {
    show: ({ paywallId, id, params = {} }: PaywallIdIdProps) =>
      path(
        `/internal_api/workflows/paywalls/${paywallId}/prices/${id}`,
        params,
      ),
  },
  previewMessages: {
    previewAttributes: ({ params = {} }: ParamsProps) =>
      path(
        "/internal_api/workflows/preview_messages/preview_attributes",
        params,
      ),
  },
  contacts: {
    index: ({ params = {} }: ParamsProps) =>
      path("/internal_api/workflows/contacts", params),
  },
} as const;
