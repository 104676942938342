import { toQueryString } from "./toQueryString";

const BASE_PATH = "/public";

type Params = Record<string, any>;

interface Args {
  params?: Params;
}

const absolutePath = (path: string, params: Params = {}) =>
  `${BASE_PATH}/${path}?${toQueryString(params)}`;

export const publicNotificationPreferences = {
  notification_preferences: {
    index: ({ params = {} }: Args) =>
      absolutePath("notification_preferences", params),
    update: ({ params = {} }: Args) =>
      absolutePath("notification_preferences", params),
  },
};
