interface SpaceEventProps {
  spaceSlug: string;
}

interface EventProps extends SpaceEventProps {
  eventSlug?: string;
}

export const newEventPath = () => "/events/new";
export const newSpaceEventPath = ({ spaceSlug }: SpaceEventProps) =>
  `/c/${spaceSlug}/events/new`;
export const eventsPath = ({ spaceSlug, eventSlug }: EventProps) =>
  `/c/${spaceSlug}/events/${eventSlug}`;
export const eventsShowPath = ({ spaceSlug, eventSlug }: EventProps) =>
  `/c/${spaceSlug}/${eventSlug}`;
export const editEventPath = (props: EventProps) => `${eventsPath(props)}/edit`;
export const editEventAttendeesPath = (props: EventProps) =>
  `${eventsPath(props)}/attendees`;
export const editEventConfirmationPath = (props: EventProps) =>
  `${eventsPath(props)}/confirmation`;
export const editEventAdvancedPath = (props: EventProps) =>
  `${eventsPath(props)}/advanced`;
